import { useEffect, useState } from "react";
import { vinnLogomark } from "assets/externalImages";
import { adminNewLeadRoute } from "routes";
import { checkAuth } from "helpers/auth-helper";

const useNotificationSocket = ({ user }) => {
  const vinnImage = vinnLogomark;
  const [permission, setPermission] = useState("denied");

  useEffect(() => {
    if (checkAuth("contacts", user)) {
      Notification.requestPermission().then((response) => {
        setPermission(response);
      });
    }
  }, []);

  const pushNotification = async (data) => {
    const options = {
      badge: vinnImage,
      body: data.message,
      icon: vinnImage,
      vibrate: [100, 50, 100],
    };
    const notification = new Notification(data.contact.name, options);

    if (data.lead) {
      notification.onclick = () => {
        window.open(adminNewLeadRoute(data.lead.id), "_self");
      };
    }

    setTimeout(notification.close.bind(notification), 7000);
  };

  const messageDispatcher = (data) => {
    if (permission === "granted") pushNotification(data);
  };

  const createSubscription = async () => {
    const consumer = (await import("consumer")).default;
    consumer.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        received(data) {
          messageDispatcher(data.body);
        },
      },
    );
  };

  useEffect(() => {
    if (checkAuth("contacts", user)) {
      createSubscription();
    }
  }, [permission]);
};

export default useNotificationSocket;
