import v1 from "requests/versions/v1.config";

const RESOURCE_URL = "/pages/inventory";

export const getInventory = (params) =>
  v1.get(RESOURCE_URL, {
    params: { view: "inventory", status: "published", ...params },
  });

export const getRecommendedInventory = (params) =>
  v1.get(`${RESOURCE_URL}/recommender`, {
    params: { view: "inventory", ...params },
  }); // recommender filters by published status
