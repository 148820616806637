import { vinnV1Api } from "services/vinn-api-v1";

const baseUrl = "/contacts";

const contactsApi = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    getContact: builder.query({
      query: (params) => ({
        url: `${baseUrl}/${params.id}`,
        params,
      }),
      providesTags: () => [{ type: "Contacts" }],
    }),
    getContacts: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      providesTags: () => [{ type: "Contacts" }],
    }),
    updateContact: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}/${data.id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: () => [{ type: "Leads" }, { type: "Contacts" }],
    }),
    mergeContacts: builder.mutation({
      query: (params) => ({
        url: `/pages/merge_contacts/${params.id}/${params.sourceId}`,
        method: "PATCH",
        params,
      }),
      invalidatesTags: () => [{ type: "Contacts" }],
    }),
    createContactCommunication: builder.mutation({
      query: ({ personId: contactId, ...data }) => ({
        url: `${baseUrl}/${contactId}/communications`,
        method: "POST",
        data,
      }),
      invalidatesTags: () => [{ type: "Communications" }],
    }),
    getContactCommunications: builder.query({
      query: ({ contactId, ...params }) => ({
        url: `${baseUrl}/${contactId}/communications`,
        params: { ...params, view: "inbox" },
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        const consumer = (await import("consumer")).default;
        try {
          await cacheDataLoaded;
          consumer.subscriptions.create(
            { channel: "ContactCommunicationChannel", room: arg.contactId },
            {
              received(data) {
                updateCachedData((draft) => {
                  draft.data.push(JSON.parse(data));
                });
              },
            },
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
      },
      providesTags: () => [{ type: "Communications" }],
    }),
  }),
});

export const {
  useGetContactQuery,
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactCommunicationsQuery,
  useUpdateContactMutation,
  useLazyGetContactCommunicationsQuery,
  useCreateContactCommunicationMutation,
  useMergeContactsMutation,
} = contactsApi;
