import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";

import { returnReductionInformation } from "helpers/apr-data-helpers";
import {
  formatAddress,
  formatPhoneNumberString,
} from "helpers/string-format-helpers";
import { defaultListingPhotos } from "helpers/listing-helpers";

import { YEARREGEX, VINREGEX } from "helpers/regex-helpers";

export const validateVin = (vin) => VINREGEX.test(vin);

export const validatePage = (pageKey, listing) => {
  switch (pageKey) {
    case "vehicleInfo":
      return {
        "vehicle.vin": validateVin(listing?.vehicle?.vin)
          ? null
          : [listing?.vehicle?.vin ? "VIN is invalid" : "VIN is required"],
        "vehicle.trim": listing?.vehicle?.trim ? null : ["Trim is required"],
        "vehicle.make": listing?.vehicle?.make?.id
          ? null
          : ["Make is required"],
        "vehicle.model": listing?.vehicle?.model?.id
          ? null
          : ["Model is required"],
        "vehicle.bodyType": listing?.vehicle?.bodyType?.id
          ? null
          : ["Body type is required"],
        kilometers: listing?.kilometers ? null : ["Kilometers is required"],
        "vehicle.year": YEARREGEX.test(listing?.vehicle?.year)
          ? null
          : ["Year is not formatted correcty"],
      };
    case "vehicleDetails":
      return {
        "vehicle.exteriorColour": listing.vehicle.exteriorColour
          ? null
          : ["Exterior Colour is required"],
        "vehicle.interiorColour": listing.vehicle.interiorColour
          ? null
          : ["Interior Colour is required"],
        "vehicle.transmission": listing.vehicle.transmission
          ? null
          : ["Transmission is required"],
        "vehicle.fuelType": listing.vehicle.fuelType
          ? null
          : ["Fuel type is required"],
        "vehicle.driveType": listing.vehicle.driveType
          ? null
          : ["Drive type is required"],
      };
    case "contactDetails":
      return {
        "user.firstName": listing.user.firstName ? null : ["Can't be blank"],
        "user.phone": listing.user.phone ? null : ["Phone number is required"],
      };
    default:
      return {};
  }
};

export const isPageComplete = (pageKey, listing) => {
  if (!listing) return pageKey === "review";
  switch (pageKey) {
    case "vehicleInfo":
      return !!(
        listing.vehicle?.vin &&
        listing.vehicle?.year &&
        listing.vehicle?.make &&
        listing.vehicle?.model &&
        listing.vehicle?.bodyType &&
        listing.vehicle?.trim &&
        listing.kilometers
      );
    case "vehiclePhotos":
      return defaultListingPhotos.every(({ label }) =>
        listing.photos?.some((listingPhoto) => label === listingPhoto.label),
      );
    case "vehicleCondition":
      return !!listing.conditionScale;
    case "vehiclePricing":
      return (
        !!(
          listing.price &&
          (!process.env.DEV_ONLY ||
            !listing.autoPriceReduction ||
            !!(
              listing.percent &&
              listing.intervalAmount &&
              listing.intervalType &&
              listing.lowestPrice
            ))
        ) || !!listing.suggestPrice
      );
    case "vehicleDetails":
      return !!(
        listing.vehicle?.exteriorColour &&
        listing.vehicle?.interiorColour &&
        listing.vehicle?.transmission &&
        listing.vehicle?.fuelType &&
        listing.vehicle?.driveType
      );
    case "vehicleLocation":
      return !!listing.address?.googlePlaceId;
    case "contactDetails":
      return !!(listing.user?.phone && listing.user?.firstName);
    default:
      return true;
  }
};

export const getReviewDetails = (pageKey, listing) => {
  switch (pageKey) {
    case "vehicleInfo":
      return {
        vin: listing.vehicle?.vin,
        year: listing.vehicle?.year,
        make: listing.vehicle?.make,
        model: listing.vehicle?.model,
        trim: listing.vehicle?.trim,
        bodyType: listing.vehicle?.bodyType,
        kilometers: listing.kilometers,
      };
    case "vehiclePhotos":
      return defaultListingPhotos.reduce((result, { label }) => {
        const photo = listing.photos?.find(
          (listingPhoto) => listingPhoto.label === label,
        );
        return { ...result, [camelCase(label)]: !!photo?.originalUrl };
      }, {});
    case "vehicleCondition":
      return {
        condition: listing.conditionScale,
        ...listing.upkeeps,
      };
    case "vehicleDetails": {
      const objectifiedOptions = listing.vehicle?.options.map((option, i) => ({
        id: i,
        name: option,
      }));
      return {
        interiorColour: listing.vehicle?.interiorColour,
        exteriorColour: listing.vehicle?.exteriorColour,
        transmission: listing.vehicle?.transmission,
        fuelType: listing.vehicle?.fuelType,
        driveType: listing.vehicle?.driveType,
        ...objectifiedOptions,
      };
    }
    case "vehicleAccessories":
      return listing.accessories;
    case "vehiclePricing": {
      let activeDetails = {};
      if (listing.suggestPrice) {
        activeDetails = { suggestPrice: listing.suggestPrice };
      } else {
        activeDetails =
          process.env.DEV_ONLY && listing.autoPriceReduction
            ? {
                price: listing.price,
                negotiableFixed: listing.negotiable
                  ? "Price is Negotiable"
                  : "Fixed Price",
                autoPriceReduction: listing.autoPriceReduction,
                text: returnReductionInformation(listing),
              }
            : {
                price: listing.price,
                negotiableFixed: listing.negotiable
                  ? "Price is Negotiable"
                  : "Fixed Price",
              };
      }
      return activeDetails;
    }
    case "vehicleDescription":
      return { description: listing.description || "No description provided" };
    case "vehicleLocation":
      return {
        location: formatAddress(listing.address, ["street", "postalCode"]),
      };
    case "contactDetails":
      return {
        name: `${listing.user?.firstName} ${listing.user?.lastName}`.trim(),
        phone: formatPhoneNumberString(listing.user?.phone),
      };
    default:
      return {};
  }
};

const mandatoryPages = [
  "vehicleInfo",
  "vehiclePhotos",
  "vehicleCondition",
  "vehicleDetails",
  "vehiclePricing",
  "vehicleLocation",
  "contactDetails",
];

export const validPages = [
  ...mandatoryPages,
  "vehicleAccessories",
  "vehicleDescription",
  "review",
];

export const isSubmittable = (listing) =>
  mandatoryPages.every((page) => isPageComplete(page, listing));

export const getCurrentPage = (listing) =>
  kebabCase(
    mandatoryPages.find((page) => !isPageComplete(page, listing)) || "review",
  );

export const parseNestedError = (error, nestedKey) =>
  Object.keys(error).reduce(
    (result, key) => ({
      ...result,
      [`${nestedKey}.${camelCase(key)}`]: error[key],
    }),
    {},
  );
