const closeCustomFieldMap = {
  // Purchase Info
  budget: "custom.cf_hPidzGQS9k1I5dlGxQJZeZum83tNB4AjzUf4HxSe7mN",
  biweeklyBudget: "custom.cf_SCvdh9B7l9ZQNOra6lvNMLhkJNzkliXmsrZzy60O6PS",
  paymentOption: "custom.cf_3938sZSkEfOQAISlMbLy1BasunEFZ1iL2d1arMUGkOl",
  currentPayment: "custom.cf_PqnW56ivAHp0hRLeDqYRDP7d8JgFan6F9JmwwbKtJJB",
  downPayment: "custom.cf_Zje1wizpweUTdzsCz9uDT15tV8xfdeZH3h8cGh8auhp",
  timeframeAmount: "custom.cf_cdNzBRH55TARC7jM068hENBrc2WINgDyrOINYT7XSIE",
  timeframeInterval: "custom.cf_UCvIjCCT6E0nWPqwSygpeiuEpcWqhooSifYj6bE7WCr",
  timeframeContext: "custom.cf_c6gkStOE0F0x9juObD4hLD1QQkpA50UIKA45Bc7QzOs",
  loanAmount: "custom.cf_CW75B7CF3qufZoSLCDelpojdHZONTAEdZmYKzIsUeoz",
  interestRate: "custom.cf_mmzGtCjqYmC0RNOGBp196Uxh3jzau7UeE2ltQ0JDs2v",
  cosigner: "custom.cf_gl80bPNehEiwm0bNS23UAQrgK7krV1dg3hNPIy6mKFf",
  // Appointment Info
  testDriveAppointmentTime:
    "custom.cf_SfxXAC5cKnfMcvgmC0o4OSuO71Dr42ycO5ejZ83ZKJA",
  phoneAppointment: "custom.cf_qrpkwB098zShI4TJNAU4v1en4CPNEsX54k7qlCC5Obc",
  // Interested Vehicle
  vin: "custom.cf_wYzoL8r0Zl6e0NGPsV4zBmv6mFGRnOvQJASzfhHZujs",
  stockNumber: "custom.cf_HcgfR1R1ZvmP2KiPJJy7AAwqWTHpkvFGn5sRMFTHjCU",
  dealership: "custom.cf_AiU2VdR2sB3l8BTmkMflQPSCmYL2xwVUWzYy9srWXH6",
  year: "custom.cf_uk6o4P23AXYz3xYiaJVQ8Ac80mcYa1CgozpDAKxdfWp",
  make: "custom.cf_750nkMQ8nVENxE6S5qZF132Z9NPwt3kHPscslv7xJPO",
  model: "custom.cf_yXE0vJMppUaMo1ACjJolpwH1ED5CDlpTilFBwFKIBTg",
  trim: "custom.cf_ldHchtRibdtwSXh4rftMz5mo0sfxQQOSgagBsNGyjSN",
  bodyType: "custom.cf_BUmU6bSgKjgZduqpLjPpH8Q25TzzdVaqYj5kzOeiP6t",
  fuelType: "custom.cf_4p59LY2NVKV4Jksvjc7UMqAiB0amCgoW6zxy1seLZK7",
  interiorColour: "custom.cf_dLBikqeVGRPqUd19qQWXOz4s0p6h4mEJIZ314omvywW",
  exteriorColour: "custom.cf_f6KFcpPYEzWTUKCBVSw5KFTkM4hBbD4HfHtdlLFuIIl",
  transmission: "custom.cf_cLzkAPrKxrOIyj7IOzMj8ciM1EdZqsDWimbxJCb6z5z",
  driveType: "custom.cf_f2vsyw7CW7Oekor9GWqe49t2Y5gTv3VlFaS7OedG5Dn",
  options: "custom.cf_mmPGON8SmkyhgjmeVj8FqVoasFjmGwhiVQzpITIOgNI",
  accessories: "custom.cf_lqBLiL904uFyJT5sLlxwclquhqqySGoIrTFNtAtNLJ5",
  // Previous Vehicle
  previousYear: "custom.cf_sozV1LzVnDHIRRLYWq302aYmEZN2lrKqo4YzP9WKITO",
  previousMake: "custom.cf_yItGwawdwMBGF2qJzz3oyUkYbLa2Tc9vNIGYHmrQNbC",
  previousModel: "custom.cf_OauH7DGOHtaSCAVVV7yNonyBn9Pe2n5xpTo8BJQ8HGS",
  previousTrim: "custom.cf_PkpZoZ5VD08B3oT5Re1DYVzhNDN74mUXaUFxdmbh9Cm",
  previousKilometers: "custom.cf_L8P08HXcKVEXLbDbY721FTqonDNeqwkI6yGdxKz0tvA",
  previousOwnershipAmount:
    "custom.cf_ngPQ9pUsZ9hFuLkwf1vUDdVkuJhmhTYuWJn4XNKmDyH",
  previousOwnershipInterval:
    "custom.cf_jO3Bz9nrllQoRolql7GVyktEWFByb8BPfotBCCumaBi",
  previousInteriorRating:
    "custom.cf_bKFD0f6v0qtvlX7edYHQZ4OpuneXENtuidFMNR5kbRS",
  previousExteriorRating:
    "custom.cf_Ea91bOSF2rsN8iR3lVessgbVw968xGvK90BaPIS8alz",
  previousCurrentPayments:
    "custom.cf_PqnW56ivAHp0hRLeDqYRDP7d8JgFan6F9JmwwbKtJJB",
  // F.O.R.M.
  family: "custom.cf_by2QOAyDUgTKkfgUrciNMW1XEHgfcHNoLOD0DRSB0C4",
  occupation: "custom.cf_vRYwwPuDR1CXdhAOlRMuS66uPRxJPu6NbYAx2Uy6hQb",
  employed: "custom.cf_FrDtk6abVIOblZO5YY9Di7ytHrSbqFYVRyqZr4dEwaB",
  currentEmployer: "custom.cf_QLEj5l4uzU0MOkhIZ8P9as6YpUMgQBXDkA1MdP2UeAF",
  currentPosition: "custom.cf_rvsF5Ze17LSUofpEQO4bBjXxQjrsTCPbbJJvNIleC5O",
  lengthOfEmployment: "custom.cf_iMgQh8mESLMx0tSjLda98zutGYtzs1giNh1kIA4b6dy",
  lengthOfEmploymentTerm:
    "custom.cf_hQjeau8CiQQ0VriDG5MzdWC1WXACFhDupnOoObMCK85",
  grossMonthlyIncome: "custom.cf_gmI7kEH1QPTcMgaeAUSGK0OFmAM4DTBnG5z8pQqj8pR",
  recreation: "custom.cf_i3A0KNEDRbti3DN6NouVrG138R3uZA0crD0EJxLhoLH",
  motivation: "custom.cf_kkjkvgP1BEszN4ZShMGmYGApckOD31c3zWS5gVENxnw",
  // Other
  notes: "custom.cf_uiABx4qpudAPqwTHoTHJqxSuvATrnEoBJrQ97t6fxSG",
  permission: "custom.cf_fFmnQ75lTpruj4foeteT38O6c1XWANVQKx3lkxU1uSZ",
  contactLocation: "custom.cf_8s85Jxb5PgY4Tu0Cg88WanDfHFGVBqUr0bXRFkYKLMj",
  contactMessage: "custom.cf_NiUuhfMT1ExSbr2CLDtRkPsjbmlDWtJHds7RmbVagQb",
  secondaryPhone: "custom.cf_BhnOPdN3IfRrR9iZwm4xiDNv3pykpgW3Cs0auDw5okV",
  secondaryEmail: "custom.cf_Lz9zCpzCfACXAHLtuG1P3Q5Yj3BcV1T0GS0G3wR6Lcs",
  submitted: "custom.cf_8oTn1VR7HEKYpz4iWkRu55Bc3u8NAvCVtmDFCvzlsNL",
  propertyRentOrOwn: "custom.cf_wQoPXF55WaE4kqDp5Ar1oRzDlOnMcyHaGFu8lTpeFoU",
  propertyMonthlyPayment:
    "custom.cf_nNQiNx8LUpJodj58UUde8sRQD04Mft7t20HEFfW6FFp",
  insurance: "custom.cf_3gVjmWhVMUqCycojddxGBtB3uQn0FUtwOXgMecKmOib",
  marble: "custom.cf_bAI6M0GFf4UZmSmN3TeN5aAnEFn18JtD3IHrlaR8jqZ",
  subprime: "custom.cf_SJRl9M1OpBoDYLoH48iytaA2Xw1wAqrVC5agk6YSsc5",
  preferredContactMethod:
    "custom.cf_MGA5qVqPNWdganDbj8kDgdiJoPxyy1lPtZ5rcDYfSJp",
  birthdate: "custom.cf_PMG39gCCnhhv5B7tEkQHxaN86Qqo0kn0G7IDDfOGCkI",
  province: "custom.cf_OOAbz5tPXl0EE6P97zjQCjGEmIbxfxIHZWIAG400QDm",
  // Tracking
  campaign: "custom.cf_ZDoV5KNbOgw4XQuKm9SETrbPswrjv1Dlx5Tpn3UTk0w",
  source: "custom.cf_xukCN9uz6s1Ov5Furw9jFgUpPXVXSTmmt4ebBoccMdW",
};

export default closeCustomFieldMap;
