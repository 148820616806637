import { createTheme } from "@mui/material/styles";
import {
  blue,
  forced,
  green,
  grey,
  hexTransparencies,
  lightBlue,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "colours";

const defaultTheme = createTheme();
const palette = {
  primary: {
    main: purple[600], // theme.palette.primary.main
  },
  onPrimary: "#001e30", // theme.palette.onPrimary
  secondary: {
    main: "#dbeeff", // theme.palette.secondary.main
    light: "#F9FBFF", // theme.palette.secondary.light
  },
  tertiary: {
    main: "#ff796c", // theme.palette.tertiary.main
    light: "#FFF2E6", // theme.palette.tertiary.light
  },
  quaternary: {
    main: "#ffecdb", // theme.palette.quaternary.main
  },
  dark: {
    main: grey[1000],
  },
  light: {
    main: "#FFFFFF",
  },
  subtle: {
    light: "#a9a9a9", // theme.palette.subtle.light
    dark: "#696969", // theme.palette.subtle.dark
  },
  basic: {
    black: forced.black, // theme.palette.basic.black
    white: forced.white, // theme.palette.basic.white
  },
  success: {
    main: "#4CAF50", // theme.palette.success.main
  },
  new: {
    main: "#F6F54D",
  },
  thirdParties: {
    facebook: "#3b5998", // theme.palette.thirdParties.facebook
    google: "#848484", // theme.palette.thirdParties.google
  },
  neutral: {
    main: "#E5E5E5", // theme.palette.neutral.main
  },
  highlight: {
    blue: "#DBEEFF",
    green: "#D8FFDB",
    yellow: "#FFF9D8",
    orange: "#FFEFD8",
    red: "#FFD9D8",
    grey: "#EEEEEE",
  },
  text: {
    primary: "rgba(31, 31, 31, 1)", // theme.palette.text.primary
    secondary: "rgba(0, 0, 0, 0.6)", // theme.palette.text.secondary
    tertiary: "rgba(31, 31, 31, 0.4)", // theme.palette.text.tertiary
    activeRatingColor: "#FF796C", // theme.palette.text.activeRatingColor
  },
  accent: {
    accent01: yellow[500], // theme.palette.accent.accent01
    accent02: pink[500], // theme.palette.accent.accent02
    accent03: orange[500], // theme.palette.accent.accent03
    accent04: lightBlue[500], // theme.palette.accent.accent04
    accent05: purple[600], // theme.palette.accent.accent05
  },
  access: {
    bkg: {
      info: blue[500], // theme.palette.access.bkg.info
      negative: red[400], // theme.palette.access.bkg.negative
      warning: orange[400], // theme.palette.access.bkg.warning
      positive: green[500], // theme.palette.access.bkg.positive
      light: {
        info: lightBlue[0], // theme.palette.access.bkg.light.info
        negative: red[100], // theme.palette.access.bkg.light.negative
        warning: orange[100], // theme.palette.access.bkg.light.warning
        positive: green[100], // theme.palette.access.bkg.light.positive
      },
      content: {
        info: blue[500], // theme.palette.access.bkg.content.info
        negative: red[500], // theme.palette.access.bkg.content.negative
        warning: orange[900], // theme.palette.access.bkg.content.warning
        positive: green[500], // theme.palette.access.bkg.content.positive
      },
    },
  },

  bkg: {
    primary: forced.white, // theme.palette.bkg.primary
    primaryGrey: grey[200], // theme.palette.bkg.primaryGrey
    secondary: grey[200], // theme.palette.bkg.secondary
    secondaryGrey: grey[300], // theme.palette.bkg.secondaryGrey
    tertiary: purple[0], // theme.palette.bkg.tertiary
    tertiaryAlt: lightBlue[0], // theme.palette.bkg.tertiaryAlt
    hover: {
      black: {
        light: forced.black + hexTransparencies[6], // theme.palette.bkg.hover.black.light
        dark: forced.black + hexTransparencies[10], // theme.palette.bkg.hover.black.dark
      },
      alt: purple[500] + hexTransparencies[6], // theme.palette.bkg.hover.alt
      overlay: forced.black + hexTransparencies[30], // theme.palette.bkg.hover.overlay
    },
  },
  border: grey[400], // theme.palette.border
  content: {
    primary: grey[1000], // theme.palette.content.primary
    secondary: grey[1000] + hexTransparencies[80], // theme.palette.content.secondary
    tertiary: grey[1000] + hexTransparencies[30], // theme.palette.content.tertiary
    tertiaryAlt: purple[600], // theme.palette.content.tertiaryAlt
  },
  // INVERSE
  inverse: {
    bkg: {
      primary: grey[1000], // theme.palette.inverse.bkg.primary
      secondary: purple[600], // theme.palette.inverse.bkg.secondary
      hover: {
        white: forced.white + hexTransparencies[12], // theme.palette.inverse.bkg.hover.white
        alt: purple[400], // theme.palette.inverse.bkg.hover.alt
      },
      overlay: forced.white + hexTransparencies[30], // theme.palette.inverse.bkg.overlay
    },
    border: grey[800], // theme.palette.inverse.border
    content: {
      primary: forced.white, // theme.palette.inverse.content.primary
      secondary: forced.white + hexTransparencies[80], // theme.palette.inverse.content.secondary
      tertiary: forced.white + hexTransparencies[40], // theme.palette.inverse.content.tertiary
      tertiaryAlt: purple[200], // theme.palette.inverse.content.tertiaryAlt
    },
  },

  actions: {
    primary: {
      main: "#E0E0E0", // theme.palette.actions.primary.main
    },
    disabledBackground: "rgba(0, 0, 0, .12)", // theme.palette.actions.disabledBackground
    boxShadow: "rgba(0, 0, 0, 0.25)", // theme.palette.actions.boxShadow
  },
  listBackground: "#EFF4FB", // theme.palette.listBackground
  inventoryFilterText: "#41474D", // theme.palette.inventoryFilterText
  inventoryFilterHover: "#C1C7CE", // theme.palette.inventoryFilterHover
  inventoryFilterActive: "#F6FBFF", // theme.palette.inventoryFilterActive
  customerPortal: {
    backgroundColor: "#FAFCFF", // theme.palette.customerPortal.backgroundColor
    titleColor: "#001352", // theme.palette.customerPortal.titleColor
  },
  design: {
    primary: "#1a4fe0", // theme.palette.design.primary
    secondary: "#001352", // theme.palette.design.secondary
    subtle: "#798086", // theme.palette.design.subtle
    background: "#f6fbff", // theme.palette.design.background
    selected: "#f0f8ff", // theme.palette.design.selected
    header: "#191c1d", // theme.palette.design.header
    subheader: "#41474d", // theme.palette.design.subheader,
    border: "#e0e0e0", // theme.palette.design.border
    alert: "#ff796c", // theme.palette.design.alert
    popover: "#49454f", // theme.palette.design.popover
  },
  privateQualified: "#32ADE6",
};

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    // Applied to slider elements
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: 20,
          height: 20,
          backgroundColor: "white",
          border: "2px solid rgba(0, 0, 0, 0.23)",
        },
        root: {
          height: "1px",
        },
      },
    },
    // Applied to dropdown elements' underline
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:hover": {
            "&:not(.Mui-disabled)": {
              "&:before": {
                borderBottom: "none",
              },
            },
          },
          "&:before": {
            borderBottom: "none",
            content: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&.Mui-disabled:before": {
            borderBottomStyle: "none",
          },
        },
        root: {
          borderRadius: 4,
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit",
          borderRadius: 4,
          color: palette.text.primary,
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // disable ripple on app, DONT MODIFY THIS
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow: "none !important",
        },
        label: {
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.25)",
        },
      },
    },
  },
  shape: {
    borderRadiusLarge: 8, // theme.shape.borderRadiusLarge
    borderRadiusMain: 4, // theme.shape.borderRadiusMain
    border: "1px solid rgba(0, 0, 0, 0.23)", // theme.shape.border
  },
  typography: {
    fontFamily: "'Outfit', sans-serif", // theme.typography.fontFamily
    button: {
      textTransform: "none", // theme.typography.button.textTransform
    },
    title: {
      desktop: 54, // theme.typography.title.desktop
      mobile: 40, // theme.typography.title.mobile
    },
    avatar: {
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "1.69",
      letterSpacing: "0.46px",
    },
    h1: {
      fontSize: 28, // theme.typography.h1.fontSize
      lineHeight: "33.89px", // theme.typography.h1.lineHeight
    },
    h2: {
      fontSize: 24, // theme.typography.h2.fontSize
      lineHeight: "29.05px", // theme.typography.h2.lineHeight
    },
    h3: {
      fontSize: 18, // theme.typography.h3.fontSize
      lineHeight: "21.78px", // theme.typography.h3.lineHeight
    },
    h4: {
      fontSize: 16, // theme.typography.h4.fontSize
      lineHeight: "18.15px", // theme.typography.h4.lineHeight
    },
    h5: {
      fontSize: 14, // theme.typography.h5.fontSize
      lineHeight: "16.94px", // theme.typography.h5.lineHeight
    },
    h6: {
      fontSize: 10, // theme.typography.h6.fontSize
    },
  },
  zIndex: {
    lowest: 1,
    portalSecondaryIcon: 100,
    imageCarousel: 200,
    formControl: 500,
    homeExpertLink: 500,
    vehicleSearchButton: 500,
    vehicleCardChip: 900,
    inventoryTitle: 1100,
    portalPopupMenu: 1200,
    drawerContent: 1300, // drawers have 1200 zIndex in mui by default
    locationPopper: 3000,
    currentLocationIcon: 4000,
    switchCountryModal: 10000,
    inventory: {
      listViewContainer: 50,
      listViewButton: 60,
      filterModal: 1000,
      moreFiltersButton: 1000,
    },
  },
  mixins: {
    centerHorizontally: {
      left: "50%",
      WebkitTransform: "translateX(-50%)",
      transform: "translateX(-50%)",
    },
    noOverflowText: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    hideScrollbar: {
      /* Hide scrollbar for Chrome, Safari and Opera */
      "&::-webkit-scrollbar": {
        display: "none",
      },
      /* Hide scrollbar for IE, Edge and Firefox */
      "-ms-overflow-style": "none" /* IE and Edge */,
      scrollbarWidth: "none" /* Firefox */,
    },
  },
  design: {
    accordionContent: {
      fontSize: 15,
      fontWeight: 300,
      lineHeight: 1.53,
      letterSpacing: 0.1,
    },
    button: {
      filled: {
        boxShadow: `0 0 0 1px ${palette.design.border}`,
        backgroundColor: palette.design.primary,
        color: "white",
        textTransform: "none",
        padding: "6px 20px",
        "&:hover": {
          backgroundColor: "white",
          color: palette.design.primary,
          borderColor: palette.design.primary,
        },
      },
      outlined: {
        boxShadow: `0 0 0 1px ${palette.design.border}`,
        border: `solid 1px ${palette.design.primary}`,
        color: palette.design.primary,
        textTransform: "none",
        padding: "6px 20px",
        "&:hover": {
          backgroundColor: palette.design.primary,
          color: "white",
          borderColor: palette.design.primary,
        },
      },
      normal: {
        height: 42,
        borderRadius: 4,
        fontSize: 15,
        padding: "0 26px",
        fontWeight: 500,
      },
      disabled: {
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      },
    },
    border: "solid 1px #dfe5ec", // theme.design.border
    legalText: {
      fontSize: 11,
      lineHeight: 1.45,
      letterSpacing: 0.5,
    },
    cardSubheader: {
      fontSize: 12,
      lineHeight: 1.33,
    },
    question: {
      fontSize: 12,
      lineHeight: 1.67,
      letterSpacing: 0.4,
    },
    chip: {
      height: 42,
      flexGrow: 0,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0.1,
      " & .MuiButton-label": {
        fontSize: 16,
      },
    },
    mobileChip: {
      height: 32,
      padding: 4,
      borderRadius: 4,
      border: "solid 1px #c6ccd2",
      " & .MuiButton-label": {
        fontSize: 13,
      },
    },
    header: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.25,
    },
    headerButton: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    detail: {
      fontSize: 13,
      lineHeight: 1.38,
      letterSpacing: "0.16px",
    },
    cardHeader: {
      fontSize: 16,
      lineHeight: 1.56,
      letterSpacing: 0.1,
    },
    sectionHeader: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.29,
      color: "#001352",
    },
    sectionSubheader: {
      fontSize: 20,
      fontWeight: 500,
    },
    sectionSubheaderLight: {
      fontSize: 20,
    },
    sectionSubheader2: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0.25,
      textAlign: "left",
    },
    title: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.33,
      color: "#fff",
    },
    dialogTitleBar: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    modalTitleCloseOnly: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
});

export default theme;
