import dayjs from "dayjs";

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const now = dayjs();

/**
 * @description Returns the amount reduced by the given percentage.
 *
 * @param {number} amount   A number
 * @param {number} percent  Percent to reduce the amount by
 *
 * @returns The amount reduced by the percentage
 */

export const calculateNextPrice = (amount, percent) =>
  amount - (percent / 100) * amount;

/**
 * @description Adds the interval to the input date and
 * returns the resulting date as a dayjs object.
 *
 * @param {dayjs}  date           A dayjs date
 * @param {number} intervalAmount Length of time
 * @param {string} intervalType   Unit of time (days, weeks, months, years)
 *
 * @return a dayjs object
 */
export const calculateNextDate = (date, intervalAmount, intervalType) =>
  dayjs(date).add(intervalAmount, intervalType);

/**
 * @description Adds the interval to the input date and
 * returns the resulting date formatted as a string.
 *
 * @param {dayjs}  date           A dayjs date
 * @param {number} intervalAmount Length of time
 * @param {string} intervalType   Unit of time (days, weeks, months, years)
 *
 * @return Formatted string of the date
 */
export const formatNextDate = (date, intervalAmount, intervalType) =>
  calculateNextDate(date, intervalAmount, intervalType).format(
    "dddd, MMMM D, YYYY",
  );

/**
 * @description Returns a string of the reduced price and date given a price, percent,
 * and time interval.
 *
 * @param {number} price            Starting price
 * @param {number} intervalAmount   Length of time
 * @param {string} intervalType     Unit of time (days, weeks, months, years)
 * @param {number} percent          Percent to reduce the price by
 *
 * @return A string of the reduced price and date
 */
export const calculateNextPriceText = (
  price,
  intervalAmount,
  intervalType,
  percent,
) =>
  `The listing price of your vehicle will automatically be reduced to ${formatCurrency.format(
    calculateNextPrice(price, percent),
  )} on ${formatNextDate(now, intervalAmount, intervalType)}.`;

/**
 * @description Returns a sentence stating how much the listing will reduce by given
 * the percent and time interval, and states the lowest price it will reach.
 *
 * @param {number} percent          Reduction percent
 * @param {number} intervalAmount   Length of time
 * @param {string} intervalType     Unit of time (days, weeks, months, years)
 * @param {number} lowestPrice      Lowest price for the listing
 *
 * @return Automatic price reduction string
 */
export const returnAprText = (
  percent,
  intervalAmount,
  intervalType,
  lowestPrice,
) =>
  `Automatically reduce the price by ${percent}% every ${intervalAmount} ${intervalType} until it reaches ${formatCurrency.format(
    parseInt(lowestPrice),
  )}`;

/**
 * @description Returns a sentence about the next price given the price reduction,
 * the price reduction schedule, and states the lowest price it will reach.
 *
 * @param {number} price            Starting price
 * @param {number} percent          Reduction percent
 * @param {number} intervalAmount   Length of time
 * @param {string} intervalType     Unit of time (days, weeks, months, years)
 * @param {number} lowestPrice      Lowest price for the listing
 *
 * @return Automatic price reduction review string
 */
export const returnReviewAprText = (
  price,
  percent,
  intervalAmount,
  intervalType,
  lowestPrice,
) =>
  `If published today, the listing price of your vehicle will be automatically reduced to ${formatCurrency.format(
    calculateNextPrice(price, percent),
  )} on ${formatNextDate(
    now,
    intervalAmount,
    intervalType,
  )} and will continue reducing by ${percent}% every ${intervalAmount} ${intervalType}, until the price reaches ${formatCurrency.format(
    parseInt(lowestPrice),
  )}`;

export const noReduction =
  "Based on you current settings, the price of your vehicle will not be automatically reduced.";
/**
 * @description If the next price is less than the lowest price, will return a string
 * stating the vehicle price will not be reduced, otherwise returns detailed text
 * about the price reduction.
 *
 * @param {number} price            Starting price
 * @param {number} percent          Reduction percent
 * @param {number} intervalAmount   Length of time
 * @param {string} intervalType     Unit of time (days, weeks, months, years)
 * @param {number} lowestPrice      Lowest price for the listing
 *
 * @return {string} Automatic price reduction review or statement that price will not be reduced
 */
export const returnReductionInformation = ({
  price,
  percent,
  lowestPrice,
  intervalAmount,
  intervalType,
}) =>
  calculateNextPrice(price, percent) > lowestPrice
    ? returnReviewAprText(
        price,
        percent,
        intervalAmount,
        intervalType,
        lowestPrice,
      )
    : noReduction;
/**
 * @description Returns a price reduction schedule.
 *
 * @param {number} price            Starting price
 * @param {number} percent          Reduction percent
 * @param {number} intervalAmount   Length of time
 * @param {string} intervalType     Unit of time (days, weeks, months, years)
 * @param {number} lowestPrice      Lowest price for the listing
 *
 * @return {string} The price reduction schedule.
 */
export const buildSchedule = (
  { price, lowestPrice, percent, intervalAmount, intervalType, publishedAt },
  altNow = publishedAt ? dayjs(publishedAt) : now,
) => {
  let newPrice = parseFloat(price);
  let newDate = altNow;
  let tempScheduleArray = [];

  while (newPrice > parseFloat(lowestPrice)) {
    tempScheduleArray.push({
      date: newDate.format("ddd, MMM DD, YYYY"),
      price: formatCurrency.format(Math.round(newPrice)),
    });

    newPrice = calculateNextPrice(newPrice, percent);
    newDate = calculateNextDate(newDate, intervalAmount, intervalType);
  }
  // For lisings that are already published and contain more than 20 items, we only show 20 items.
  // It displays the first five items, displays a break
  // It then display the one prior to the CURRENT DATE and the following 4 items.
  // It then displays a break and the last 5 items.
  if (tempScheduleArray.length > 20 && publishedAt) {
    tempScheduleArray = [
      ...tempScheduleArray.slice(0, 5),
      { date: "...", price: "" },
      ...tempScheduleArray.filter(
        (item) =>
          dayjs(item.date) > now.subtract(intervalAmount, intervalType) &&
          dayjs(item.date) < now.add(intervalAmount * 5, intervalType),
      ),
      { date: "...", price: "" },
      tempScheduleArray[tempScheduleArray.length - 1],
    ];
    // For unpublished listings that contain more than 20 items, we only show 20 items.
    // Displays a schedule with only future dates.
  } else if (tempScheduleArray.length > 20 && !publishedAt) {
    tempScheduleArray = [
      ...tempScheduleArray.slice(0, 10),
      { date: "...", price: "" },
      tempScheduleArray[tempScheduleArray.length - 1],
    ];
  }

  return tempScheduleArray;
};
/**
 * @description Calculates the reduced price given the date published.
 *
 * @param {number}  price               New price
 * @param {number}  percent             Reduction percent
 * @param {number}  intervalAmount      Length of time
 * @param {string}  intervalType        Unit of time (days, weeks, months, years)
 * @param {number}  lowestPrice         Lowest price for the listing
 * @param {string}  publishedAt         Acceptable dayjs string
 * @param {boolean} autoPriceReduction  True for automatic price reduction, false otherwise
 *
 * @return {string} The reduced price
 */
export const calculateReducedPrice = (
  {
    price,
    lowestPrice,
    percent,
    intervalAmount,
    intervalType,
    publishedAt,
    autoPriceReduction,
  },
  altNow = now,
) => {
  let newPrice = parseFloat(price);
  let newDate = dayjs(publishedAt);
  const tempScheduleArray = [];
  if (autoPriceReduction && publishedAt) {
    while (newPrice > parseFloat(lowestPrice) && parseFloat(lowestPrice) > 0) {
      if (newDate > altNow) break;
      tempScheduleArray.push(parseFloat(newPrice));
      newPrice = calculateNextPrice(newPrice, percent);
      newDate = calculateNextDate(newDate, intervalAmount, intervalType);
    }
  } else {
    tempScheduleArray.push(newPrice);
  }
  const reducedPrice = tempScheduleArray[tempScheduleArray.length - 1];
  return reducedPrice ? Math.round(reducedPrice) : 0;
};
