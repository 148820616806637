export const notifier = {
  enqueueSnackbar: "notifier/enqueueSnackbar",
  closeSnackbar: "notifier/closeSnackbar",
  removeSnackbar: "notifier/removeSnackbar",
};

export const enqueueSnackbar = (message, options = {}) => ({
  type: notifier.enqueueSnackbar,
  notification: {
    message,
    options,
    key: options.key || new Date().getTime() + Math.random(),
  },
});

export const closeSnackbar = (key) => ({
  type: notifier.closeSnackbar,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: notifier.removeSnackbar,
  key,
});
