import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import thunk from "redux-thunk";
import { baseReducer } from "reducers/base-reducer";
import { communicationReducer } from "reducers/communication-reducer";
import { helpReducer } from "reducers/help-reducer";
import { inventoryReducer } from "reducers/inventory-reducer";
import { modalReducer } from "reducers/modal-reducer";
import { notifierReducer } from "reducers/notifier-reducer";
import { tutorialReducer } from "reducers/tutorial-reducer";
import { vdpReducer } from "reducers/vdp-reducer";
import { resourceTableReducer } from "reducers/resource-table-reducer";
import { evergreenReducer } from "reducers/evergreen-reducer";
// TODO: Update when V1 is used for all API calls
import { vinnApi } from "services/vinn-api";
import { vinnV1Api } from "services/vinn-api-v1";
import { listingReducer } from "reducers/listing-reducer";
import { leadReducer } from "reducers/lead-reducer";

export const makeStore = () =>
  configureStore({
    reducer: {
      admins: resourceTableReducer("admins"),
      callGroups: resourceTableReducer("callGroups"),
      communication: communicationReducer,
      customers: resourceTableReducer("customers"),
      dealers: resourceTableReducer("dealers"),
      groups: resourceTableReducer("groups"),
      lead: leadReducer,
      leadsTest: resourceTableReducer("leadsTest"),
      mappings: resourceTableReducer("mappings"),
      notifier: notifierReducer,
      tutorial: tutorialReducer,
      evergreen: evergreenReducer,
      vehicles: resourceTableReducer("vehicles"),
      [baseReducer.name]: baseReducer.reducer,
      [vinnApi.reducerPath]: vinnApi.reducer,
      [vinnV1Api.reducerPath]: vinnV1Api.reducer,
      [inventoryReducer.name]: inventoryReducer.reducer,
      [helpReducer.name]: helpReducer.reducer,
      [vdpReducer.name]: vdpReducer.reducer,
      [modalReducer.name]: modalReducer.reducer,
      [listingReducer.name]: listingReducer.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(vinnApi.middleware)
        .concat(vinnV1Api.middleware)
        .concat(thunk),
  });

// can set this to true to debug ssr
export const wrapper = createWrapper(makeStore, { debug: false });
