import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  desktopView: true,
  locationAutocompleteValue: null,
};

export const baseReducer = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateDesktopView(state, action) {
      state.desktopView = action.payload;
    },
    updateLocationAutocompleteValue(state, action) {
      state.locationAutocompleteValue = action.payload;
    },
  },
});
