export const defaultState = {
  page: 0,
  search: "",
  limit: 50,
  sortBy: "",
  data: [],
  count: -1,
  loading: true,
  filters: {},
};

const replaceMemberInCollectionById = (member, collection) => {
  const index = collection.findIndex((ele) => ele.id === member.id);
  const result = [...collection];
  if (index > -1) {
    result[index] = member;
  }
  return result;
};

// eslint-disable-next-line max-len
export const resourceTableReducer =
  (resource, initialState = defaultState) =>
  (state = initialState, action) => {
    switch (action.type) {
      case `${resource}/pageChanged`: {
        return { ...state, page: action.payload };
      }
      case `${resource}/sortChanged`: {
        return { ...state, page: 0, sortBy: action.payload };
      }
      case `${resource}/limitChanged`: {
        return { ...state, page: 0, limit: action.payload };
      }
      case `${resource}/filtersChanged`: {
        return { ...state, page: 0, filters: action.payload };
      }
      case `${resource}/updateCount`: {
        return {
          ...state,
          count: action.payload,
          loading: false,
        };
      }
      /* I want to merge updateData and loading into event like
  names but want to wait unitl we use Redux asyncThunks */
      case `${resource}/updateData`: {
        return {
          ...state,
          data: action.payload.data,
          // TODO: Update when V1 is used for all API calls
          count: action.payload.count ?? state.count,
          loading: false,
        };
      }
      case `${resource}/updateMemberSuccess`: {
        return {
          ...state,
          data: replaceMemberInCollectionById(action.payload, state.data),
        };
      }
      case `${resource}/loading`: {
        return { ...state, loading: true, data: [] };
      }
      default:
        return state;
    }
  };
