import cloneDeep from "lodash/cloneDeep";

import { updateAccount } from "requests/auth-api";
import { parseNestedError } from "helpers/tutorial-helpers";

const initialState = {
  page: {
    key: "vehicleInfo",
    number: 0,
    title: "Vehicle Information",
    subtitle: "What vehicle are you looking to sell?",
  },
  loading: false,
  readOnly: false,
  isCameraOpen: false,
  conditionScale: "poor",
  textFieldErrorMessage: "🧐 Hmmm, something is wrong here.",
  errors: {},
  accordionState: "standard",
  pages: [],
};

export const tutorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case "tutorial/loadListing": {
      const photos = state.listing.photos.map((photo) => {
        const existingPhoto = action.payload.photos?.find(
          ({ label }) => label === photo.label,
        );
        return existingPhoto
          ? {
              ...photo,
              blob: "",
              isNew: false,
              ...existingPhoto,
            }
          : photo;
      });

      const address = {
        ...state.listing.address,
        ...action.payload.address,
      };

      const user = {
        ...state.listing.user,
        ...action.payload.user,
      };

      return {
        ...state,
        oldId: null,
        newId: null,
        loading: false,
        errors: {},
        listing: {
          ...state.listing,
          ...action.payload,
          address,
          user,
          readOnly: ["archived", "published", "sold"].includes(
            action.payload.status,
          ),
          photos,
          vinPhoto:
            action.payload.photos?.find(({ position }) => position === "vin") ||
            state.listing.vinPhoto,
        },
      };
    }

    case "tutorial/reloadListing": {
      return {
        ...state,
        reloadId: action.payload,
      };
    }

    case "tutorial/updateListing": {
      return { ...state, listing: action.payload, loading: false };
    }

    case "tutorial/resetState": {
      return { ...initialState };
    }

    case "tutorial/updateListingPhoto": {
      const listingCopy = cloneDeep(state.listing);
      const copy = cloneDeep(state.listing.photos);

      const foundImage = copy.find(
        ({ key }) => action.payload.position === key,
      );

      foundImage.id = action.payload.id;
      foundImage.blob = action.payload.blob;
      foundImage.isNew = action.payload.isNew;
      listingCopy.photos = copy;
      return { ...state, listing: listingCopy };
    }

    case "tutorial/updateVinPhoto": {
      const listingCopy = cloneDeep(state.listing);
      listingCopy.vinPhoto = {
        ...listingCopy.vinPhoto,
        ...action.payload,
      };
      return { ...state, listing: listingCopy, loading: false };
    }

    case "tutorial/updateLoading": {
      return { ...state, loading: action.payload };
    }

    case "tutorial/updateReadOnly": {
      return { ...state, readOnly: action.payload };
    }

    case "tutorial/updateErrors": {
      return { ...state, errors: action.payload, loading: false };
    }

    case "tutorial/updateAccordion": {
      return { ...state, accordionState: action.payload };
    }

    case "tutorial/incrementPage": {
      let newPageNumber = state.page.number + 1;
      if (newPageNumber >= state.pages.length - 1) {
        newPageNumber = state.pages.length - 1;
      }
      return {
        ...state,
        page: state.pages[newPageNumber],
        loading: false,
      };
    }

    case "tutorial/decrementPage": {
      return {
        ...state,
        page: state.pages[state.page.number - 1],
        loading: false,
      };
    }

    case "tutorial/setPage": {
      return {
        ...state,
        page: action.payload,
        loading: false,
      };
    }

    case "tutorial/setPages": {
      return {
        ...state,
        pageCount: action.payload.length,
        pages: action.payload,
      };
    }

    case "tutorial/setIsCameraOpen": {
      return { ...state, isCameraOpen: action.payload };
    }

    default:
      return state;
  }
};

export const handleUpdateCustomer = (customerToUpdate) => (dispatch) =>
  new Promise((resolve, reject) => {
    updateAccount(customerToUpdate)
      .then((response) => {
        // Auth returns a string response instead of a number
        if (response.status === "success") {
          dispatch({ type: "auth/userUpdateSuccess", payload: response.data });
          dispatch({
            type: "tutorial/loadListing",
            payload: { user: customerToUpdate },
          });
        }
        resolve();
      })
      .catch((error) => reject(parseNestedError(error.data.errors, "user")));
  });
