import { PHONEREGEX } from "helpers/regex-helpers";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import pluralize from "pluralize";
import get from "lodash/get";
import indefinite from "indefinite";

/**
 * @description Returns an input string in title case (first letter of each word capitalized).
 *
 * @param {string}  rawString   Input string
 *
 * @return {string} The input string in title case
 */
export const capitalizeString = (rawString) => startCase(toLower(rawString));

/**
 * @description Capitalizes the first letter of string.
 *
 * @param {string}  rawString   Input string
 *
 * @return {string} The input string with the first letter capitalized
 */
export const capitalizeFirstLetter = (unformattedString) => {
  const castedString = String(unformattedString);
  return castedString.charAt(0).toUpperCase() + castedString.slice(1);
};

/**
 * @description Takes an address Object and returns the properties as a
 * comma separated string.
 * The default format is: "street, city, province, postal code, country"
 *
 * skippedParts will omit certain pieces from the output.
 * For example, if you want to omit the street and postal code, you can pass
 * ['street', 'postalCode'] as skippedParts resulting in:
 * "city, province, country"
 *
 * @param {string}  address             Object with various address properties
 * @param {string}  address.street      Street name
 * @param {string}  address.city        City name
 * @param {string}  address.province    Province name
 * @param {string}  address.postalCode  Postal Code
 * @param {string}  address.country     Country name
 *
 * @param {Array<string>}  skippedParts         Array of address parts that will be skipped
 *
 * @return {string} The formatted address.
 */

export const formatAddress = (address, skippedParts = []) =>
  ["street", "city", "province", "postalCode", "country"]
    // Removes the unnecessary address keys (aka skipped parts)
    .filter((key) => !skippedParts.includes(key))
    // Removes the address keys that don't exist in the address object
    .filter((key) => !!address && !!address[key])
    // Returns the actual address parts
    .map((key) => {
      if (key === "country") {
        if (address[key] === "CA") {
          return "Canada";
        }
        if (address[key] === "US") {
          return "United States";
        }
        return address[key];
      }
      return address[key];
    })
    // Joins the address parts with a comma and space
    .join(", ");

/**
 * @description Takes a number and appends kms/miles to the end. If number is not a
 * valid number or less than zero, will return a string that kms/miles not specified.
 * Uses kms/miles if desktop is false and Kilometers/Miles if desktop is true.
 * If country code is 'ca' will use kilometers, else uses miles.
 *
 * @param {number}  number          Number of kilometers or miles - is converted to integer
 * @param {boolean} [desktop]       Determines whether to use long or short distance format
 * @param {string}  [country=ca]    Determines whether to use kms or miles
 *
 * @return {string} The number with distance appended to it, or a message if number invalid
 */

export const formatOdometerString = (
  number,
  desktop = true,
  country = "ca",
) => {
  const shortSuffix = country === "ca" ? "kms" : "miles";
  const shortPrefix = country === "ca" ? "Kms" : "Miles";
  const prefix = country === "ca" ? "Kilometers" : "Miles";
  const intNumber = parseInt(number);
  if (!Number.isNaN(intNumber) && intNumber >= 0) {
    return `${intNumber.toLocaleString()} ${shortSuffix}`;
  }
  if (desktop) {
    return `${prefix} not specified`;
  }
  return `${shortPrefix} not specified`;
};

/**
 * @description Returns input number formatted as a price if number is valid
 * (prepends dollar sign and adds comma separator if necessary). Otherwise returns the string
 * noPriceText (default value is 'Contact for price').
 *
 * @param {number}  number          Input number - is converted to integer
 * @param {string}  [noPriceText]   String used if valid number not provided
 *
 * @return {string} The number formatted as price, or noPriceText string
 */
export const formatPriceString = ({
  price,
  noPriceText = "Contact for price",
  showDecimals = false,
}) => {
  const intNumber = parseFloat(price);
  if (!Number.isNaN(intNumber) && intNumber > 0) {
    return `$${intNumber.toLocaleString(undefined, {
      minimumFractionDigits: showDecimals ? 2 : 0,
      maximumFractionDigits: showDecimals ? 2 : 0,
    })}`;
  }

  return noPriceText;
};

/**
 * @description Returns a string of vehicle information in the form of year make model trim
 * (if includeTrim = true). If vehicle.make or vehicle.model are not strings,
 * will use vehicle.make.name or vehicle.model.name respectively.
 *
 *
 * @param {Object}        vehicle              Vehicle object
 * @param {string|Object} vehicle.make         Vehicle's make
 * @param {string|Object} vehicle.model        Vehicle's model
 * @param {string|number} vehicle.year         Vehicle's year
 * @param {string}        [vehicle.make.name]  Vehicle's make
 * @param {string}        [vehicle.model.name] Vehicle's model
 * @param {string}        [vehicle.trim]       Optional, the vehicle's trim
 * @param {boolean}       [includeTrim]        True will display the vehicle's trim
 * @param {boolean}       [includeYear]        True will display the vehicle's year
 * @param {boolean}       [includeArticle]     True will display the vehicle's article
 *                                             (eg. a Ford, an Acura)
 * @param {boolean}       [capitalizeArticle]  True will capitalize the article
 *                                             (eg. A Ford, An Acura)
 *
 * @return {string} Formatted vehicle name
 */

export const formatVehicleName = (
  vehicle,
  {
    includeTrim = false,
    includeYear = false,
    includeArticle = false,
    capitalizeArticle = false,
  },
) => {
  const make =
    typeof vehicle?.make === "string"
      ? vehicle?.make
      : vehicle?.make?.name || vehicle?.model?.make?.name;
  const model =
    typeof vehicle?.model === "string" ? vehicle?.model : vehicle?.model?.name;
  let result = `${make} ${model}`;
  if (includeTrim) {
    result = `${result} ${vehicle?.trim}`.trim();
  }
  if (includeYear) {
    result = `${vehicle?.year} ${result}`;
  }
  if (includeArticle) {
    result = `${indefinite(result, { capitalize: capitalizeArticle })}`;
  }
  return result;
};

/**
 * @description Truncates input string at the given length (default is 40 characters) and appends
 * an ellipses. If string length is shorter than length param then returns the string unchanged.
 *
 * @param {string}  string    Input string
 * @param {number}  [length]  Number that determines length of truncated string
 *
 * @return {string}           The truncated string
 */
export const truncateString = (string, length = 40) => {
  let result = string;
  if (typeof string === "string" && string.length > length) {
    result = `${result.substring(0, length)}...`;
  }
  return result;
};

/**
 * @description Formats phone number to (###) ###-####. or +## (###) ###-####.
 *
 * @param {string}  string    Input string
 *
 * @return {string}           The formatted phone number string
 */
export const formatPhoneNumberString = (string) => {
  const cleaned = `${string}`.replace(/\D/g, "");
  const match = cleaned.match(PHONEREGEX);
  if (!match) {
    return string;
  }
  if (match[1]) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return string;
};

/**
 * @description Checks a given string and returns true if it ends in a vowel, otherwise false.
 *
 * @param {length}  number    Length of string to return
 *
 * @return {string}           Random alpha-numeric string
 */
export const startsWithVowel = (string) =>
  ["a", "e", "i", "o", "u"].indexOf(string[0]) !== -1;

/**
 * @description Takes a string and pluralizes the last word in it.

 * @param {string}  name         Name to pluralize
 *
 * @return {string} plural form of the passed argument
 */

export const pluralizeString = (...rest) => {
  if (!rest.length) {
    return null;
  }

  // Rule definitions
  pluralize.addIrregularRule("Mercedes Benz", "Mercedes Benzes");

  return pluralize(...rest);
};

/**
 * @description Returns a random alpha-numeric string of the given length.
 *
 * @param {length}  number    Length of string to return
 *
 * @return {string}           Random alpha-numeric string
 */
export const randomAlphanumericString = (length = 10) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

// TODO: Write unit tests for this function
/**
 * @description Returns parsed string with dynamic fields replaced by attributes of the object.
 *
 * @param {string}  string    String to parse,
 *                            dynamic fields should be identified by the following pattern:
 *                            {{field}}
 *
 * @param {Object}  object    Object to use for dynamic field values.
 *
 * @return {string}           Parsed string
 */
export const parseDynamicString = (string, object) => {
  const dynamicFields = string.match(/({{\S+}})/gm);
  return dynamicFields?.length > 0
    ? dynamicFields?.reduce(
        (result, field) =>
          result.replace(field, get(object, field.slice(2, field.length - 2))),
        string,
      )
    : string;
};

/**
 * @description Returns a formatted string of the user's name
 *
 * @param {Object}        user              User object
 *
 * @return {string} Formatted user name
 */
export const formatUserName = ({ firstName, lastName }) =>
  lastName ? `${firstName} ${lastName}` : firstName;
