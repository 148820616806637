import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  errors: {},
  formValue: {
    helpMessage: "",
    helpName: "",
    helpEmail: "",
    helpPhone: "",
  },
  loading: false,
};

export const helpReducer = createSlice({
  name: "help",
  initialState,
  reducers: {
    setErrors(state, action) {
      state.errors = action.payload.errors;
      state.loading = false;
    },
    setFormValue(state, action) {
      state.formValue[action.payload.key] = action.payload.value;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    resetState: () => initialState,
  },
});
