import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  modal: null,
  variant: "default",
};

export const modalReducer = createSlice({
  name: "modal",
  initialState,
  reducers: {
    show(state, action) {
      state.modal = action.payload.modal;
      state.variant = action.payload.variant;
    },
    close: () => initialState,
  },
});
