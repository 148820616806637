export const homeRoute = "/";
export const articlesRoute = "/articles";
export const paymentCalculatorRoute = "/payment-calculator";
export const customerListingsRoute = "/inventory";
export const customerListingRoute = "/listing/[id]/[page]";
// TO:DO replace customerListingRoute usage with customerListingRouteGenerator
export const customerListingRouteGenerator = ({ id, page }) =>
  `/listing/${id}/${page}`;
export const customerAccountPreferencesRoute = "/account";
export const customerAccountProfileRoute = "/account/profile";
export const customerAccountSecurityRoute = "/account/security";
export const customerAccountNotificationsRoute = "/account/notifications";
export const customerNotificationsRoute = "/notifications";
export const customerChatsRoute = "/chats";
export const customerChatRoute = (id) => `/chats/${id}`;
export const evergreenRoute = "/research/:make/:model";
export const findMyCarRoute = "/find-my-car";
export const inventoryRoute = "/cars";
export const signInRoute = "/sign-in";
export const passwordResetRoute = "/password-reset";
export const passwordResetRequestRoute = "/password-reset/request";
export const privacyRoute = "/privacy";
export const signUpRoute = "/sign-up";
export const thankYouLandingRoutes = [
  "/thank-you-landing",
  "/thank-you-ev",
  "/thank-you-trade",
  "/thank-you-credit",
  "/thank-you-help",
];
export const tosRoute = "/tos";
export const vdpRoute = "/car/[year]/[make]/[model]/[city]/[vehicleId]";
export const vdpRouteGenerator = ({ year, make, model, city, vehicleId }) =>
  `/car/${year}/${make}/${model}/${city}/${vehicleId}`;
export const imFeelingLuckyRoute = "/im-feeling-lucky";
export const careersRoute = "/careers";

// External links
export const vinnReviewsRoute =
  "https://www.google.com/search?q=vinn+auto&rlz=1C5CHFA_enCA966CA966&oq=vinn+auto&aqs=chrome.0.69i59j46i175i199i512j69i59j0i512j46i175i199i512j69i60j69i61l2.1205j0j4&sourceid=chrome&ie=UTF-8#lrd=0x548f13c3efe80a13:0x51af7c10b7170213,1,,,";
export const vinnCareersRoute =
  "https://vinnautomotivetechnologies.bamboohr.com/jobs/?source=aWQ9MTA=";
export const closeRoute = "https://app.close.com/lead";

// Socials
export const vinnTwitterRoute = "https://twitter.com/vinnauto";
export const vinnFacebookRoute = "https://www.facebook.com/vinncanada";
export const vinnInstagramRoute = "https://www.instagram.com/vinncanada";
export const vinnPinterestRoute = "https://www.pinterest.ca/VINNAuto/_saved/";
export const vinnTiktokRoute = "https://www.tiktok.com/@vinnauto";
export const vinnLinkedInRoute = "https://www.linkedin.com/company/vinnauto";
export const vinnYouTubeRoute = "https://www.youtube.com/@VINNauto";
// Admin
export const adminRoute = "/admin";
export const adminDealershipMapRoute = "/admin/dealership-map";
export const adminVehicleExpertLeaderboardRoute =
  "/admin/vehicle-expert-leaderboard";
export const adminMergeContactRoute = (id) => `/admin/merge-contacts/${id}`;
export const adminSignInRoute = "/admin/sign-in";
export const adminListingsRoute = "/admin/listings";
export const adminListingRoute = (id) => `/admin/listings/${id}`;
export const adminDealershipsRoute = "/admin/dealerships";
export const adminCloseLeadsRoute = "/admin/close-leads";
export const adminCloseLeadRoute = (id) => `/admin/close-leads/${id}`;
export const adminNewLeadsRoute = "/admin/new-leads";
export const adminNewLeadRoute = (id) => `/admin/new-leads/${id}`;
export const adminFlowsRoute = "/admin/settings/flows";
export const adminFlowRoute = (id) => `/admin/settings/flows/${id}`;
export const adminReportedUsersRoute = "/admin/reported-users";
export const adminReportedUserRoute = (id) => `/admin/reported-users/${id}`;

export const adminSpecsRoute = "/admin/specs";
export const adminModelsRoute = "/admin/specs/models";
export const adminModelRoute = (id) => `/admin/specs/models/${id}`;

export const adminLifestylesRoute = "/admin/specs/lifestyles";
export const adminLifestyleRoute = (id) => `/admin/specs/lifestyles/${id}`;

// Dealer
export const dealerRoute = "/dealer";
export const dealerSignInRoute = "/dealer/sign-in";
export const dealerProfileRoute = "/dealer/account/profile";
export const dealerSecurityRoute = "/dealer/account/security";
export const dealerInventoryRoute = "/dealer/inventory";
