import { getRecommendedInventory } from "requests/v1/inventory-api";

const initialState = {
  genericVehicle: {},
  vehiclesNearYou: [],
  recommendedListings: [],
  vehiclesNearYouResponseIncludesModels: false,
};

export const evergreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "evergreen/setGenericVehicle": {
      return { ...state, genericVehicle: action.payload };
    }
    case "evergreen/setVehiclesNearYou": {
      return { ...state, vehiclesNearYou: action.payload };
    }
    case "evergreen/setVehiclesNearYouResponseIncludesModels": {
      return {
        ...state,
        vehiclesNearYouResponseIncludesModels: action.payload,
      };
    }
    case "evergreen/setRecommendedListings": {
      return { ...state, recommendedListings: action.payload };
    }

    default:
      return state;
  }
};

export const fetchVehiclesNearYou = (filters) => async (dispatch) => {
  const nearYouParams = {
    sortBy: null,
    orderBy: null,
    page: 0,
    latitude: filters.location.latitude,
    longitude: filters.location.longitude,
    makeId: filters.makeId,
    modelId: filters.modelId,
  };
  getRecommendedInventory(nearYouParams).then((recommendedMakeModel) => {
    if (recommendedMakeModel.status === 200) {
      const recommendedData = recommendedMakeModel.data.data;
      if (!recommendedData.length) {
        delete nearYouParams.modelId;
        getRecommendedInventory(nearYouParams).then((recommendedMake) => {
          dispatch({
            type: "evergreen/setVehiclesNearYouResponseIncludesModels",
            payload: false,
          });
          dispatch({
            type: "evergreen/setVehiclesNearYou",
            payload: recommendedMake.data.data,
          });
        });
      } else {
        dispatch({
          type: "evergreen/setVehiclesNearYouResponseIncludesModels",
          payload: true,
        });
        dispatch({
          type: "evergreen/setVehiclesNearYou",
          payload: recommendedData,
        });
      }
    }
  });
};

export const fetchRecommendedListings = (filters) => async (dispatch) => {
  const recommendedParams = {
    sortBy: null,
    orderBy: null,
    page: 0,
    latitude: filters.location.latitude,
    longitude: filters.location.longitude,
  };
  getRecommendedInventory(recommendedParams).then((recommendedResponse) => {
    if (recommendedResponse.status === 200) {
      const recommendedData = recommendedResponse.data.data;

      dispatch({
        type: "evergreen/setRecommendedListings",
        payload: recommendedData,
      });
    }
  });
};
