import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    overflowX: "hidden",
    width: "100%",
    height: "inherit",
  },
  container: {
    display: "block",
    maxWidth: "100%",
    margin: "20px",
    marginTop: "20vh",
    textAlign: "center",
  },
  card: {
    textAlign: "center",
    background: "lightgray",
    padding: "30px",
    margin: "10px",
    borderRadius: "15px",
    minWidth: "150px",
  },
  browsers: {
    justifyContent: "center",
    display: "flex",
    flexFlow: "wrap",
  },
  content: {
    marginTop: "10px",
  },
}));
