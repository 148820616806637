import React from "react";
import Image from "components/image-containers/Image/Image.component";

import Typography from "@mui/material/Typography";

import {
  braveIcon,
  chromeIcon,
  edgeIcon,
  firefoxIcon,
  operaIcon,
} from "assets/externalImages";
import { useStyles } from "./UpdateBrowser.styles";

const IMG_DIMENSIONS = {
  width: 75,
  height: 75,
};

const UpdateBrowser = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <Typography variant="h1" data-cy="update-browser-text">
          Update your browser
        </Typography>
        <Typography variant="body1">
          The browser you&apos;re using is no longer supported. Update to a
          newer browser for the best experience.
        </Typography>
      </div>

      <div className={classes.browsers}>
        <a className={classes.card} href="https://www.microsoft.com/en-us/edge">
          <div className={classes.image}>
            <Image
              {...IMG_DIMENSIONS}
              alt="Microsoft Edge Logo"
              src={edgeIcon}
            />
          </div>
          <div className={classes.content}>
            <Typography variant="body1">Microsoft Edge</Typography>
          </div>
        </a>

        <a
          className={classes.card}
          href="https://www.google.com/intl/en_ca/chrome/"
        >
          <div className={classes.image}>
            <Image
              {...IMG_DIMENSIONS}
              alt="Google Chrome Logo"
              src={chromeIcon}
            />
          </div>
          <div className={classes.content}>
            <Typography variant="body1">Google Chrome</Typography>
          </div>
        </a>

        <a
          className={classes.card}
          href="https://www.mozilla.org/en-CA/firefox/new/"
        >
          <div className={classes.image}>
            <Image
              {...IMG_DIMENSIONS}
              alt="Mozilla Firefox Logo"
              src={firefoxIcon}
            />
          </div>
          <div className={classes.content}>
            <Typography variant="body1">Mozilla Firefox</Typography>
          </div>
        </a>

        <a className={classes.card} href="https://www.opera.com/download">
          {" "}
          <div className={classes.image}>
            <Image {...IMG_DIMENSIONS} alt="Opera Logo" src={operaIcon} />
          </div>
          <div className={classes.content}>
            <Typography variant="body1">Opera</Typography>
          </div>
        </a>

        <a className={classes.card} href="https://brave.com/download/">
          {" "}
          <div className={classes.image}>
            <Image {...IMG_DIMENSIONS} alt="Brave Logo" src={braveIcon} />
          </div>
          <div className={classes.content}>
            <Typography variant="body1">Brave</Typography>
          </div>
        </a>
      </div>
    </div>
  );
};

export default UpdateBrowser;
