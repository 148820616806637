import { vinnV1Api } from "services/vinn-api-v1";

export const integrationsSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    twilioAuthToken: builder.query({
      query: (params) => ({
        url: "/integrations/twilio/auth_token",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useLazyTwilioAuthTokenQuery } = integrationsSlice;
