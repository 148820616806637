import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {
    id: -1,
    user: {},
    vehicle: {
      options: [],
      make: {},
    },
    accessories: [],
    upkeep: [],
  },
  errors: {},
  loading: true,
};

export const listingReducer = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    update(state, action) {
      state.data = action.payload.data;
      state.errors = {};
      state.loading = false;
    },
    setErrors(state, action) {
      state.errors = action.payload.errors;
      state.loading = false;
    },
  },
});
