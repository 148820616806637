import api from "requests/versions/api.config";

const authPrefix = () => {
  const pathPrefixes = {
    "/admin": "admin_",
    "/dealer": "dealer_",
  };
  const key = Object.keys(pathPrefixes).find((path) =>
    window.location.pathname.includes(path),
  );

  return pathPrefixes[key] || "";
};

export const signupWithEmail = (user, prefix = authPrefix()) =>
  api.post(`${prefix}auth`, user);

export const signInWithEmail = (credentials, prefix = authPrefix()) =>
  api.post(`${prefix}auth/sign_in`, credentials);

export const signInWithProvider = (provider, prefix = authPrefix()) =>
  api.get(`${prefix}auth/${provider}`);

export const signOut = (prefix = authPrefix()) =>
  api.delete(`${prefix}auth/sign_out`);

export const requestResetPassword = (
  { email, redirectUrl },
  prefix = authPrefix(),
) => api.post(`${prefix}auth/password`, { email, redirectUrl });

export const resetPassword = (credentials, prefix = authPrefix()) =>
  api.put(`${prefix}auth/password`, credentials);

export const updateAccount = (attributes, prefix = authPrefix()) =>
  api.put(`${prefix}auth`, attributes);

export const destroyAccount = (prefix = authPrefix()) =>
  api.delete(`${prefix}auth`);
