import { createApi } from "@reduxjs/toolkit/query/react";
import api from "requests/versions/api.config";
import { HYDRATE } from "next-redux-wrapper";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await api({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// Define a service using a base URL and expected endpoints
export const vinnApi = createApi({
  reducerPath: "vinn",
  baseQuery: axiosBaseQuery({
    baseUrl: "",
  }),
  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: ["Listings", "Dealerships", "Leads"],
  endpoints: () => ({}),
});
