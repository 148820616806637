import dayjs from "dayjs";
import reduce from "lodash/reduce";
import isEqual from "lodash/isEqual";

/**
 * @description Recursive function returning an object
 * containing all the keys and values of the first object
 * that are different in the second object.
 *
 * @param {updatedObject}  object    An object to compare
 * @param {originalObject}  object    An object to compare
 * @param {nestedAttributes}  array    Optional array of nested attributes to compare as well
 *
 * @return {object}           Object with all the different keys and values
 */
export const getDifferenceBetweenObjects = (
  updatedObject,
  originalObject,
  nestedAttributes = [],
) =>
  originalObject
    ? reduce(
        updatedObject,
        (result, updatedValue, key) => {
          if (nestedAttributes.includes(key)) {
            const nestedDifference = getDifferenceBetweenObjects(
              updatedValue,
              originalObject[key],
            );
            if (Object.keys(nestedDifference).length > 0) {
              return {
                ...result,
                [key]: nestedDifference,
              };
            }
            return result;
          }
          if (!isEqual(updatedValue, originalObject[key])) {
            return {
              ...result,
              [key]: updatedValue,
            };
          }
          return result;
        },
        {},
      )
    : updatedObject;

/**
 * @description Recursive function returning an object
 * where all the keys have a different casing.
 * Primarily used to convert between snake_case (BE) & camelCase (FE).
 *
 * @param {obj}  object    An object to convert
 * @param {newCase}  object    A function to convert the key casing
 * @param {keyExclusionRegex}  regex    Optional regex to exclude keys from being changed
 *
 * @return {object}           Object with the altered keys
 */
export const deepChangeObjectKeysCase = (
  obj = {},
  newCase,
  exclusionOptions = {},
) => {
  const keyExcluder = exclusionOptions.regex
    ? (key) => (exclusionOptions.regex.test(key) ? key : newCase(key))
    : newCase;

  const changeKeyCase = exclusionOptions.symbol
    ? (key) =>
        key
          .split(exclusionOptions.symbol)
          .map(keyExcluder)
          .join(exclusionOptions.symbol)
    : keyExcluder;
  // DayJS type variables are objects that shouldn't be changed
  if (dayjs.isDayjs(obj)) return obj;
  // An array is considered an object
  if (typeof obj === "object" && !Array.isArray(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [changeKeyCase(key)]: obj[key]
          ? deepChangeObjectKeysCase(obj[key], changeKeyCase, exclusionOptions)
          : obj[key],
      }),
      {},
    );
  }
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      deepChangeObjectKeysCase(item, changeKeyCase, exclusionOptions),
    );
  }
  return obj;
};
