import axios from "axios";
import { deepChangeObjectKeysCase } from "helpers/object-helpers";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import QueryString from "qs";

const apiConnector = (apiPath, keyExclusionRegex) => {
  const changeCase = (obj, newCase) =>
    deepChangeObjectKeysCase(obj, newCase, { regex: keyExclusionRegex });

  const api = axios.create({
    baseURL: `${
      typeof window !== "undefined"
        ? window.location.origin
        : process.env.NEXT_HOST
    }/${apiPath}`,
    paramsSerializer: (params) =>
      QueryString.stringify(changeCase(params, snakeCase), {
        arrayFormat: "brackets",
      }),
  });

  api.interceptors.request.use((config) => ({
    ...config,
    params: changeCase(config.params, snakeCase),
    data: changeCase(config.data, snakeCase),
  }));

  api.interceptors.response.use(
    (response) => {
      if (
        response.headers["content-type"] === "application/json; charset=utf-8"
      ) {
        return {
          ...response,
          data: changeCase(response.data, camelCase),
          errors: changeCase(response.errors, camelCase),
        };
      }
      return response;
    },
    // eslint-disable-next-line prefer-promise-reject-errors
    ({ response }) =>
      Promise.reject({
        ...response,
        data: changeCase(response.data, camelCase),
      }),
  );
  return api;
};

export default apiConnector;
