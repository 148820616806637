/**
 * @description Compares a users auth to the level of required auth for a specific resource
 * to see if the user has that access.
 *
 * @param {any} resource  Resource to check auth for
 * @param {any} authLevel Level of auth required, be read or full or none
 * @param {any} user  user with permission on user and on group
 *
 * @return {boolean} True if auth is allowed, false if not
 */
const authTranslator = { no_access: 0, read_access: 1, full_access: 2 };

export const checkAuth = (resource, user, authLevel = "full_access") => {
  if (!user || !resource) return false;
  if (authLevel === "no_access") return true;

  const userAccess =
    user.permissions?.find((permission) => permission.name === resource) ||
    user.group?.permissions?.find((permission) => permission.name === resource);
  return authTranslator[userAccess?.rank] >= authTranslator[authLevel];
};
