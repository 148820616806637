import { notifier } from "actions/notifier-actions";

const initialState = {
  notifications: [],
};
// closeSnackbar and  removeSnackbar are not used currently but are available
export const notifierReducer = (state = initialState, action) => {
  switch (action.type) {
    case notifier.enqueueSnackbar:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    case notifier.closeSnackbar:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case notifier.removeSnackbar:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
};
