import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import set from "lodash/set";
import closeCustomFieldMap from "helpers/close-custom-field-map";

export const initialState = {
  data: {},
  errors: {},
  loading: true,
  notFound: false,
  recommendedVehicles: [],
  loadingRecommendedVehicles: false,
  selectedFilters: {
    year: [],
    make: [],
    model: [],
    bodyType: [],
    fuelType: [],
    exteriorColour: [],
    transmission: [],
    driveType: [],
  },
  place: {},
  leadParams: {},
  contactId: null,
};

const mapDataToFilters = (data, fields) => {
  const interestedVehicle = {};
  fields.forEach((field) => {
    interestedVehicle[field] = data[closeCustomFieldMap[field]];
  });
  return interestedVehicle; // This gives only filters that exist
};

export const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "lead/fetchById/pending": {
      return { ...state, loading: true };
    }
    case "lead/fetchById/fulfilled": {
      const filters = {};
      const { lead, errors } = action.payload;
      const fields = Object.keys(state.selectedFilters);
      const cleanedFilters = mapDataToFilters(lead, fields);
      Object.keys(cleanedFilters).forEach((field) => {
        if (cleanedFilters[field] instanceof Array) {
          filters[field] = [];
          cleanedFilters[field].forEach((item) => {
            filters[field].push({ data: item, toggled: true });
          });
        } else {
          filters[field] = [{ data: cleanedFilters[field], toggled: true }];
        }
      });

      return {
        ...state,
        data: lead,
        errors,
        selectedFilters: filters,
        loading: false,
        notFound: false,
      };
    }
    case "lead/fetchById/newFulfilled": {
      const filters = {};
      const lead = action.payload;
      const fields = Object.keys(state.selectedFilters);
      const cleanedFilters = mapDataToFilters(lead, fields);
      Object.keys(cleanedFilters).forEach((field) => {
        if (cleanedFilters[field] instanceof Array) {
          filters[field] = [];
          cleanedFilters[field].forEach((item) => {
            filters[field].push({ data: item, toggled: true });
          });
        } else {
          filters[field] = [{ data: cleanedFilters[field], toggled: true }];
        }
      });

      return {
        ...state,
        data: lead,
        selectedFilters: filters,
        loading: false,
        notFound: false,
      };
    }
    case "lead/fetchById/rejected": {
      return { ...state, loading: false, notFound: true };
    }
    case "lead/updateById/pending": {
      return { ...state };
    }
    case "lead/updateById/fulfilled": {
      const filters = {};
      const { lead, field } = action.payload;
      const cleanedFilters = mapDataToFilters(
        lead,
        Object.keys(state.selectedFilters),
      );
      Object.keys(cleanedFilters).forEach((key) => {
        if (cleanedFilters[key] instanceof Array) {
          filters[key] = [];
          cleanedFilters[key].forEach((item) => {
            const itemToggled = state.selectedFilters[key].find(
              (obj) => obj.data === item,
            )?.toggled;
            filters[key].push({
              data: item,
              toggled: typeof itemToggled === "boolean" ? itemToggled : true,
            });
          });
        } else {
          const itemToggled = state.selectedFilters[key][0]?.toggled;
          filters[key] = [
            {
              data: cleanedFilters[key],
              toggled: typeof itemToggled === "boolean" ? itemToggled : true,
            },
          ];
        }
      });
      const errors = omit(state.errors, field);
      return {
        ...state,
        data: lead,
        selectedFilters: filters,
        errors,
      };
    }
    case "lead/updateByName/fulfilled": {
      const { name, value } = action.payload;
      const data = cloneDeep(state.data);
      data[name] = value;
      return { ...state, data };
    }
    case "lead/updateById/rejected": {
      return { ...state, errors: { ...state.errors, ...action.payload } };
    }
    case "lead/updateContactById/pending": {
      // If place is given use place
      return { ...state, place: action.payload || state.place };
    }
    case "lead/updateContactById/fulfilled": {
      const { contact, field, index } = action.payload;
      // const location = action.payload.location || state.location;
      const updatedLead = cloneDeep(state.data);
      updatedLead.contacts[index] = contact;
      const errors = omit(state.errors.contacts, field);
      return {
        ...state,
        data: updatedLead,
        errors,
      };
    }
    case "lead/updateContactById/rejected": {
      const { error, index } = action.payload;
      const errors = cloneDeep(state.errors);
      set(errors, `contacts[${index}]`, error);
      return { ...state, errors };
    }
    case "lead/updateLocation": {
      return { ...state, place: action.payload || state.place };
    }
    case "lead/fetchRecommendedVehicles/pending": {
      return { ...state, loadingRecommendedVehicles: true };
    }
    case "lead/fetchRecommendedVehicles/fulfilled": {
      return {
        ...state,
        recommendedVehicles: action.payload,
        loadingRecommendedVehicles: false,
      };
    }
    case "lead/resetState": {
      return { ...initialState };
    }
    case "lead/toggleFilter": {
      const selectedFiltersCopy = cloneDeep(state.selectedFilters);
      const itemIndex = selectedFiltersCopy[action.payload.filter].findIndex(
        (obj) => obj.data === action.payload.item.data,
      );
      selectedFiltersCopy[action.payload.filter][itemIndex] = {
        data: action.payload.item.data,
        toggled: !action.payload.item.toggled,
      };
      return { ...state, selectedFilters: selectedFiltersCopy };
    }
    case "lead/createFromExisting/pending": {
      return { ...state, loading: true };
    }
    case "lead/createFromExisting/fulfilled": {
      return { ...state, data: action.payload, loading: false };
    }
    case "lead/createFromExisting/rejected": {
      return { ...state, loading: false };
    }
    case "lead/updateAppointmentRequired": {
      return { ...state, appointmentRequired: action.payload };
    }
    default: {
      return state;
    }
  }
};
