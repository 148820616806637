import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { CacheProvider } from "@emotion/react";
import { isIE } from "react-device-detect";
import { Analytics } from "@vercel/analytics/react";

import axios from "axios";
import { SnackbarProvider } from "notistack";
import Cookies from "cookies";
import QueryString from "qs";
import PropTypes from "prop-types";
import parser from "ua-parser-js";
import mediaQuery from "css-mediaquery";
import createEmotionCache from "createEmotionCache";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";

// Client-side cache, shared for the whole session of the user in the browser.

import theme from "theme";
import { wrapper } from "store";
import { SideMenuContext } from "context/sidemenu";
import { DealershipContext } from "context/dealership";

import UpdateBrowser from "components/content/UpdateBrowser/UpdateBrowser.component";
import Notifier from "hooks/Notifier.component";

// Fonts
import "@fontsource/outfit/200.css";
import "@fontsource/outfit/300.css";
import "@fontsource/outfit"; // Defaults to weight 400 with normal variant.
import "@fontsource/outfit/400.css";
import "@fontsource/outfit/500.css";
import "@fontsource/outfit/600.css";
import "@fontsource/outfit/700.css";

import "react-phone-input-2/lib/style.css";
import "../global.css";
import { UserProvider } from "context/user";
import { DialerProvider } from "context/dialer";
import useNotificationSocket from "hooks/useNotificationSocket.hook";
import { deepChangeObjectKeysCase } from "helpers/object-helpers";

const clientSideEmotionCache = createEmotionCache();

export function reportWebVitals() {}
function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, ...rest } = props;
  const { store, props: pageProps } = wrapper.useWrappedStore(rest);
  const { user, deviceType } = pageProps;
  const [sideMenu, setSideMenu] = useState(true);
  const [dealership, setDealership] = useState();
  useNotificationSocket({ user });
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === "mobile" ? "0px" : "1024px",
    }),
  });

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  if (isIE) {
    return <UpdateBrowser />;
  }
  return (
    <>
      <StyledEngineProvider injectFirst>
        <DealershipContext.Provider value={{ dealership, setDealership }}>
          <SideMenuContext.Provider value={{ sideMenu, setSideMenu }}>
            <CacheProvider value={emotionCache}>
              <Provider store={store}>
                <ThemeProvider
                  theme={{
                    ...theme,
                    props: {
                      ...theme.props,
                      // Change the default options of useMediaQuery
                      MuiUseMediaQuery: { ssrMatchMedia },
                    },
                  }}
                >
                  <UserProvider initialUser={user}>
                    <DialerProvider user={user}>
                      <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Notifier />
                          <Component {...pageProps} />
                          <Analytics />
                        </LocalizationProvider>
                      </SnackbarProvider>
                    </DialerProvider>
                  </UserProvider>
                </ThemeProvider>
              </Provider>
            </CacheProvider>
          </SideMenuContext.Provider>
        </DealershipContext.Provider>
      </StyledEngineProvider>
    </>
  );
}
MyApp.getInitialProps = wrapper.getInitialAppProps(() => async ({ ctx }) => {
  if (!ctx.req) return {}; // return no props if client side
  const cookies = new Cookies(ctx.req, ctx.res);
  const authCookie = cookies.get("auth-headers") || "";
  const authHeaders = authCookie ? JSON.parse(authCookie) : {};

  const api = axios.create({
    headers: {
      ...authHeaders,
    },
    baseURL: process.env.MARKETPLACE_API_HOST,
    paramsSerializer: (params) =>
      QueryString.stringify(deepChangeObjectKeysCase(params, snakeCase), {
        arrayFormat: "brackets",
      }),
  });
  let prefix = "";
  const deviceType =
    parser(ctx.req.headers["user-agent"]).device.type || "desktop";
  if (ctx.req.url.startsWith("/admin")) prefix = "/admin_";
  if (ctx.req.url.startsWith("/dealer")) prefix = "/dealer_";
  try {
    const res = await api.get(`${prefix}auth/validate_token`);

    return {
      user: deepChangeObjectKeysCase(res.data, camelCase),
      deviceType,
    };
  } catch (e) {
    return {
      user: null,
    };
  }
});

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({}),
  user: PropTypes.shape({}),
  deviceType: PropTypes.string,
};

MyApp.defaultProps = {
  pageProps: {},
  user: null,
  deviceType: null,
};

export default wrapper.withRedux(MyApp);
