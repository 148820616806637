// Logos
export const vinnLogo = `${process.env.CDN_HOST}/assets/images/logo/vinn_logo.svg`; // SVG logo
export const vinnLogoInverse = `${process.env.CDN_HOST}/assets/images/logo/vinn_logo_inverse.svg`; // SVG inverse logo
export const vinnLogoBlack = `${process.env.CDN_HOST}/assets/images/logo/vinn_logo_black.svg`; // SVG black logo
export const vinnLogoWhite = `${process.env.CDN_HOST}/assets/images/logo/vinn_logo_white.svg`; // SVG white logo
// Logomark
export const vinnLogomark = `${process.env.CDN_HOST}/assets/images/logo/vinn_logomark.svg`; // SVG logomark
export const vinnLogomarkBlack = `${process.env.CDN_HOST}/assets/images/logo/vinn_logomark_black.svg`; // SVG black logomark
export const vinnLogomarkWhite = `${process.env.CDN_HOST}/assets/images/logo/vinn_logomark_white.svg`; // SVG white logomark
// Logotext
export const vinnLogotextBlack = `${process.env.CDN_HOST}/assets/images/logo/vinn_logotext_black.svg`; // SVG white logotext
export const vinnLogotextWhite = `${process.env.CDN_HOST}/assets/images/logo/vinn_logotext_white.svg`; // SVG white logotext

// Browser Icons
export const edgeIcon = `${process.env.CDN_HOST}/assets/images/browsers/edge.png`;
export const chromeIcon = `${process.env.CDN_HOST}/assets/images/browsers/chrome.png`;
export const firefoxIcon = `${process.env.CDN_HOST}/assets/images/browsers/firefox.png`;
export const operaIcon = `${process.env.CDN_HOST}/assets/images/browsers/opera.png`;
export const braveIcon = `${process.env.CDN_HOST}/assets/images/browsers/brave.png`;

// Flags
export const canadianFlag = `${process.env.CDN_HOST}/assets/images/country-flags/canada.png`;
export const americanFlag = `${process.env.CDN_HOST}/assets/images/country-flags/usa.png`;

export const comingSoon = `${process.env.CDN_HOST}/assets/images/coming_soon.webp`;
export const greyLoader = `${process.env.CDN_HOST}/assets/images/greyLoading.png`;
export const vinn404Image = `${process.env.CDN_HOST}/assets/images/404Image.png`;

// Dealership map Pins
export const dealershipMapIcon = `${process.env.CDN_HOST}/assets/images/inventory/dealershipMapIcon.png`;
export const certifiedDealershipMapIcon = `${process.env.CDN_HOST}/assets/images/inventory/certifiedDealershipMapIcon.png`;
export const dealershipPinWhite = `${process.env.CDN_HOST}/assets/images/inventory/dealershipPinWhite.png`;
export const dealershipPinBlue = `${process.env.CDN_HOST}/assets/images/inventory/dealershipPinBlue.png`;
export const dealershipPinLightBlue = `${process.env.CDN_HOST}/assets/images/inventory/dealershipPinLightBlue.png`;

// Homepage images
export const homePageOG = `${process.env.CDN_HOST}/assets/images/homepage/homepage-og-image.jpg`;
export const homeBlueGradientCropped = `${process.env.CDN_HOST}/assets/images/homepage/home-blue-gradient-cropped.svg`;
export const homeGradient = `${process.env.CDN_HOST}/assets/images/homepage/home-gradient.png`;
export const homeGradientMobile = `${process.env.CDN_HOST}/assets/images/homepage/home-gradient-mobile.webp`;
export const homepageBoxes = `${process.env.CDN_HOST}/assets/images/homepage/boxes.png`;
export const homepageStar = `${process.env.CDN_HOST}/assets/images/homepage/star.png`;
export const homepageFree = `${process.env.CDN_HOST}/assets/images/homepage/free.png`;
export const homepageNatalieTestimonial = `${process.env.CDN_HOST}/assets/images/homepage/natalie-testimonial.webp`;
export const homepageBMW = `${process.env.CDN_HOST}/assets/images/homepage/feature-cars/bmw.webp`;
export const homepagePorsche = `${process.env.CDN_HOST}/assets/images/homepage/feature-cars/porsche.webp`;
export const homepageSubaruOutback = `${process.env.CDN_HOST}/assets/images/homepage/feature-cars/subaru-outback.webp`;
export const homepageBlueArrow = `${process.env.CDN_HOST}/assets/images/homepage/blue-arrow.svg`;
export const homepageTwirlyArrow = `${process.env.CDN_HOST}/assets/images/homepage/homepageTwirlyArrow.svg`;
export const homepageTwirlyArrowMobile = `${process.env.CDN_HOST}/assets/images/homepage/homepageTwirlyArrowMobile.svg`;
export const homepageVinnHappyCustomers = `${process.env.CDN_HOST}/assets/images/homepage/vinn-happy-customers.webp`;
export const homepageArrow = (variant) =>
  `${process.env.CDN_HOST}/assets/images/homepage/${variant}-arrow.svg`; // Variant can be 'white' or 'blue'

// Experiences
export const experiencesAdventure = `${process.env.CDN_HOST}/assets/images/homepage/experiences/adventure.webp`;
export const experiencesAdventureGif = `${process.env.CDN_HOST}/assets/images/homepage/experiences/adventure.webm`;
export const experiencesFamilyCruiser = `${process.env.CDN_HOST}/assets/images/homepage/experiences/family-cruiser.webp`;
export const experiencesFamilyCruiserGif = `${process.env.CDN_HOST}/assets/images/homepage/experiences/family-cruiser.webm`;
export const experiencesFirstCar = `${process.env.CDN_HOST}/assets/images/homepage/experiences/first-car.webp`;
export const experiencesFirstCarGif = `${process.env.CDN_HOST}/assets/images/homepage/experiences/first-car.webm`;
export const experiencesGoGreen = `${process.env.CDN_HOST}/assets/images/homepage/experiences/go-green.webp`;
export const experiencesGoGreenGif = `${process.env.CDN_HOST}/assets/images/homepage/experiences/go-green.webm`;
export const experiencesSuperCar = `${process.env.CDN_HOST}/assets/images/homepage/experiences/supercar.webp`;
export const experiencesSuperCarGif = `${process.env.CDN_HOST}/assets/images/homepage/experiences/supercar.webm`;

// Testimonials
export const testimonial5 = `${process.env.CDN_HOST}/assets/images/homepage/testimonials/testimonial-5.png`;
export const testimonial6 = `${process.env.CDN_HOST}/assets/images/homepage/testimonials/testimonial-6.png`;
export const testimonial7 = `${process.env.CDN_HOST}/assets/images/homepage/testimonials/testimonial-7.png`;

// Evergreen
export const thumbnailPlayIcon = `${process.env.CDN_HOST}/assets/images/homepage/thumbnail-play-icon.png`;

// Inventory
export const filterButtonIcon = `${process.env.CDN_HOST}/assets/images/filterButtonIcon.png`;

// Vehicle spec icons
export const makeIcon = `${process.env.CDN_HOST}/assets/images/vdp/makeIcon.png`;
export const modelIcon = `${process.env.CDN_HOST}/assets/images/vdp/modelIcon.png`;
export const priceIcon = `${process.env.CDN_HOST}/assets/images/vdp/priceIcon.png`;
export const statusIcon = `${process.env.CDN_HOST}/assets/images/vdp/statusIcon.png`;
export const fuelIcon = `${process.env.CDN_HOST}/assets/images/vdp/fuelTypeIcon.png`;
export const transmissionIcon = `${process.env.CDN_HOST}/assets/images/vdp/transmissionIcon.png`;
export const mileageIcon = `${process.env.CDN_HOST}/assets/images/inventory/cardMileageIcon.svg`;
export const vehicleLocationIcon = `${process.env.CDN_HOST}/assets/images/inventory/cardLocationIcon.svg`;

// C2C assets
export const rotate270 = `${process.env.CDN_HOST}/assets/images/gifs/rotate270.gif`;
export const rotate90 = `${process.env.CDN_HOST}/assets/images/gifs/rotate90.gif`;
export const vinWindshield = `${process.env.CDN_HOST}/assets/images/C2C/vin_windshield.png`;
export const vinDoor = `${process.env.CDN_HOST}/assets/images/C2C/vin_door.png`;
export const vinBoth = `${process.env.CDN_HOST}/assets/images/C2C/vin_both.png`;
export const sampleCheque = `${process.env.CDN_HOST}/assets/images/C2C/sample_cheque.png`;
export const c2cPhotoUploadOverlay = (type) =>
  `${process.env.CDN_HOST}/assets/images/C2C/${type}_white.png`;
export const c2cPhotoUploadImage = (type) =>
  `${process.env.CDN_HOST}/assets/images/C2C/${type}_small.webp`;

export const additionalPhotos = `${process.env.CDN_HOST}/assets/images/C2C/additional_small.webp`;

// Private Sale landing page assets

export const carDollarIcon = `${process.env.CDN_HOST}/assets/images/private_sale/carDollarIcon.png`;
export const carsIcon = `${process.env.CDN_HOST}/assets/images/private_sale/carsIcon.png`;
export const doubleAvatarIcon = `${process.env.CDN_HOST}/assets/images/private_sale/doubleAvatarIcon.png`;
export const iphoneStep2 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02.png`;
export const iphoneStep3 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_03.png`;
export const iphoneStep4 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04.png`;
export const noDollarIcon = `${process.env.CDN_HOST}/assets/images/private_sale/noDollarIcon.png`;
export const stepsPhones = {
  mobile1: `${process.env.CDN_HOST}/assets/images/private_sale/step1phoneNoShadow.png`,
  desktop1: `${process.env.CDN_HOST}/assets/images/private_sale/step1phoneNoShadowDesktop.png`,
  mobile2: `${process.env.CDN_HOST}/assets/images/private_sale/step2phoneNoShadow.png`,
  desktop2: `${process.env.CDN_HOST}/assets/images/private_sale/step2phoneNoShadowDesktop.png`,
  mobile3: `${process.env.CDN_HOST}/assets/images/private_sale/step3phoneNoShadow.png`,
  desktop3: `${process.env.CDN_HOST}/assets/images/private_sale/step3phoneNoShadowDesktop.png`,
  mobile4: `${process.env.CDN_HOST}/assets/images/private_sale/step4phoneNoShadow.png`,
  desktop4: `${process.env.CDN_HOST}/assets/images/private_sale/step4phoneNoShadowDesktop.png`,
};
export const step1Animation = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_01_animation.png`;
export const step1AnimationDesktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_01_animation_desktop.png`;
export const step2Animation = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation.png`;
export const step2AnimationDesktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation_desktop.png`;
export const step2Animation1 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation_1.png`;
export const step2Animation1Desktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation_1_desktop.png`;
export const step2Animation2 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation_2.png`;
export const step2Animation2Desktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_02_animation_2_desktop.png`;
export const step3Animation = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_03_animation.png`;
export const step3AnimationDesktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_03_animation_desktop.png`;
export const step4Animation = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation.png`;
export const step4AnimationDesktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation_desktop.png`;
export const step4Animation1 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation_1.png`;
export const step4Animation1Desktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation_1_desktop.png`;
export const step4Animation2 = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation_2.png`;
export const step4Animation2Desktop = `${process.env.CDN_HOST}/assets/images/private_sale/iphone_step_04_animation_2_desktop.png`;

export const wfh = `${process.env.CDN_HOST}/assets/images/private_sale/work_from_home.png`;

// VINN employees
export const andrewVE = `${process.env.CDN_HOST}/assets/images/team/AndrewScott-Cropped.jpg`;
export const deborahVE = `${process.env.CDN_HOST}/assets/images/team/DebraPomeroy-Cropped.jpg`;
export const scottVE = `${process.env.CDN_HOST}/assets/images/team/ScottCarter-Cropped.jpg`;
export const chetFlanagan = `${process.env.CDN_HOST}/assets/images/team/ChetFlanagan-Cropped.jpg`;

export const andrewVESmall = `${process.env.CDN_HOST}/assets/images/team/AndrewScott-Cropped-Small.webp`;
export const deborahVESmall = `${process.env.CDN_HOST}/assets/images/team/DebraPomeroy-Cropped-Small.webp`;
export const scottVESmall = `${process.env.CDN_HOST}/assets/images/team/ScottCarter-Cropped-Small.webp`;
export const chetFlanaganSmall = `${process.env.CDN_HOST}/assets/images/team/ChetFlanagan-Cropped-Small.webp`;

// Lottie animations
export const emailCheckmarkAnimation =
  "https://assets8.lottiefiles.com/packages/lf20_9osmalex.json";
export const confettiAnimation =
  "https://assets7.lottiefiles.com/packages/lf20_lgjeadh2.json";
export const paperPlaneAnimation =
  "https://assets4.lottiefiles.com/packages/lf20_x9elszuk.json";

// COOP
export const CoopBanner = `${process.env.CDN_HOST}/assets/images/coop/coop-banner.jpg`;
export const CoopImage = `${process.env.CDN_HOST}/assets/images/coop/coop.jpg`;
