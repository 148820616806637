import { vinnApi } from "services/vinn-api";

const baseUrl = "/api/leads";

export const LeadsSlice = vinnApi.injectEndpoints({
  endpoints: (builder) => ({
    updateLead: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${baseUrl}/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: () => [{ type: "Leads" }],
    }),
    migrateCloseLead: builder.mutation({
      query: (data) => ({
        url: "/api/close/leads/migrate",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useMigrateCloseLeadMutation, useUpdateLeadMutation } =
  LeadsSlice;
