// Dependencies
import React, { useState, useEffect } from "react";
import NextImage from "next/image";
import cloudflareLoader from "helpers/images/cloudflare-loader";

const Image = ({
  loader = cloudflareLoader,
  fallback = null,
  src,
  ...props
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  return (
    <NextImage
      loader={loader}
      onError={setError}
      src={error && fallback ? fallback : src}
      {...props}
    />
  );
};

export default Image;
