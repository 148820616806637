export default function cloudflareLoader({ src, width, quality }) {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");
  return `https://cdn.vinnauto.com/cdn-cgi/image/${paramsString}/${
    src.includes("X-Amz-Signature=") ? src : encodeURI(src)
  }`;
}
