import cloneDeep from "lodash/cloneDeep";

export const initialState = {
  communications: [],
  person: {
    id: null,
    type: null,
    name: "",
    phone: "",
  },
};

export const communicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "communication/updateCommunications": {
      return { ...state, communications: action.payload };
    }
    case "communication/updateCommunicationById": {
      const communicationsCopy = cloneDeep(state.communications);
      const newCommunications = communicationsCopy.map((communication) =>
        communication.id === action.payload.id ? action.payload : communication,
      );
      return { ...state, communications: newCommunications };
    }
    case "communication/updateIncomingCommunications": {
      const communicationsCopy = cloneDeep(state.communications);
      if (
        action.payload.data.recipient.id === state.person.id ||
        action.payload.data.sender.id === state.person.id
      ) {
        if (
          !communicationsCopy.find(({ id }) => id === action.payload.data.id)
        ) {
          communicationsCopy.push({
            ...action.payload.data,
            createdAt: action.payload.data.created_at,
            futureTime: action.payload.data.future_time,
          });
        }
      }
      return { ...state, communications: communicationsCopy };
    }
    case "communication/updatePerson": {
      return { ...state, person: action.payload };
    }
    default: {
      return state;
    }
  }
};
