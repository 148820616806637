import { createApi } from "@reduxjs/toolkit/query/react";
import v1 from "requests/versions/v1.config";
import { HYDRATE } from "next-redux-wrapper";

const tagTypes = [
  "Groups",
  "Users",
  "Listings",
  "Leads",
  "LeadsStatuses",
  "Dealerships",
  "DealerGroups",
  "Vehicles",
  "Communications",
  "CallGroups",
  "CallFlows",
  "Conversations",
  "Chats",
  "Conversations",
  "Notifications",
  "NotificationsCount",
  "Models",
  "LifeStyles",
  "ReportedUsers",
  "Contacts",
];

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await v1({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (error) {
      if (error) {
        return {
          error: {
            status: error.status,
            data: error.data || error.message,
          },
        };
      }
      return {
        error: {
          data: "Unknown error",
        },
      };
    }
  };

// Define a service using a base URL and expected endpoints
export const vinnV1Api = createApi({
  reducerPath: "vinnV1",
  tagTypes,
  baseQuery: axiosBaseQuery({
    baseUrl: "",
  }),
  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    // this is from the next-redux-wrapper docs
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
});
