import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  cardsHidden: true,
  filterModalOpen: false,
  firstClick: false,
  mobileListView: true,
  userLocation: null,
  validAttributes: {
    make: [],
    model: [],
    bodyType: [],
    condition: [],
    price: [],
    kilometers: [],
    year: [],
    exteriorColour: [],
    interiorColour: [],
    transmission: [],
    fuelType: [],
    driveType: [],
    lifestyle: [],
  },
  loading: {
    vehicles: true,
    count: true,
    filters: false,
    map: true,
  },
  publishedListingsCount: 0,
};

export const inventoryReducer = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setUserLocation(state, action) {
      state.userLocation = action.payload;
    },
    setCardsHidden(state, action) {
      state.cardsHidden = action.payload;
    },
    setFirstClick(state, action) {
      state.firstClick = action.payload;
    },
    updateLoading(state, action) {
      state.loading[action.payload.key] = action.payload.value;
    },
    setFilterModalOpen(state, action) {
      state.filterModalOpen = action.payload;
    },
  },
});
