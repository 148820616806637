import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const initialState = {
  activeInquiryTab: "testDrive",
  date: "",
  errors: {},
  formValue: {
    inquiryMessage: "",
    inquiryName: "",
    inquiryEmail: "",
    inquiryPhone: "",
  },
  isFlexible: true,
  time: 0,
  loading: false,
};

export const vdpReducer = createSlice({
  name: "vdp",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setActiveInquiryTab(state, action) {
      state.activeInquiryTab = action.payload;
    },
    setFlexibility(state, action) {
      state.isFlexible = action.payload;
    },
    setTime(state, action) {
      state.time = action.payload.time;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
      state.loading = false;
    },
    setFormValue(state, action) {
      state.formValue[action.payload.key] = action.payload.value;
    },
    resetDate(state) {
      state.date = dayjs(dayjs().format("YYYY-MM-DD"));
      state.time = initialState.time;
      state.isFlexible = initialState.isFlexible;
    },
    resetState: () => initialState,
  },
});

export const getRange = (time) => {
  switch (time) {
    case 10:
      return "8am-12pm";
    case 14:
      return "12pm-4pm";
    case 18:
      return "4pm-8pm";
    default:
      return "Select a time";
  }
};

export const checkIfFilled = (form) => async (dispatch, getState) => {
  const state = getState();
  const { vdp } = state;
  switch (form) {
    case "inquiry":
      return (
        vdp.formValue.inquiryEmail &&
        vdp.formValue.inquiryName &&
        vdp.formValue.inquiryPhone
      );
    case "testDrive":
      return (
        ((vdp.date && vdp.time) || vdp.isFlexible) &&
        vdp.formValue.inquiryEmail &&
        vdp.formValue.inquiryName &&
        vdp.formValue.inquiryPhone
      );
    default:
      return false;
  }
};
