import {
  c2cPhotoUploadImage,
  c2cPhotoUploadOverlay,
} from "assets/externalImages";

export const defaultListingPhotos = [
  {
    label: "three_quarter",
    text: "Add a photo of the front & side of your car",
    title: "3/4 Turn",
  },
  {
    label: "left",
    text: "Add a photo of the left of your car",
    title: "Left",
  },
  {
    label: "rear",
    text: "Add a photo of the back of your car",
    title: "Rear",
  },
  {
    label: "trunk",
    text: "Add a photo of the trunk of your car",
    title: "Trunk",
  },
  {
    label: "right",
    text: "Add a photo of the right of your car",
    title: "Right",
  },
  {
    label: "front",
    text: "Add a photo of the front of your car",
    title: "Front",
  },
  {
    label: "cockpit",
    text: "Add a photo of the cockpit of your car",
    title: "Cockpit",
  },
  {
    label: "dash",
    text: "Add a photo of the dash of your car",
    title: "Dash",
  },
  {
    label: "vin",
    text: "Add a photo of your VIN",
    title: "VIN",
  },
].map(({ label, text, title }) => ({
  key: label,
  label,
  text,
  title,
  blob: "",
  open: false,
  outline: c2cPhotoUploadOverlay(label),
  drawing: c2cPhotoUploadImage(label),
  hasImage: false,
}));

/**
 * @description Determine if a listing is from a private seller.
 *
 * @param {object} listing The listing to check
 */
export const isPrivateSeller = (listing) =>
  listing.seller?.sellerType === "private";

/**
 * @description Determine if listing updates are disabled
 *
 * @param {object} listing The listing to check
 */
export const isListingDisabled = (listing) =>
  ["draft", "published", "archived", "sold"].includes(listing?.status) ||
  !!listing?.deletedAt;
