import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Device } from "@twilio/voice-sdk";

import { useLazyTwilioAuthTokenQuery } from "services/v1-slices/integrations-slice";
import { useUpdateLeadMutation } from "services/slices/leads-slice";
import { enqueueSnackbar } from "actions/notifier-actions";
import { checkAuth } from "helpers/auth-helper";
import { DialerContext } from "context/dialer";

const useTwilioDialer = ({ user }) => {
  const [updateLead] = useUpdateLeadMutation();
  const [twilioAuthTrigger] = useLazyTwilioAuthTokenQuery();
  const {
    device,
    setDevice,
    session,
    setSession,
    callStatus,
    setCallStatus,
    callDetails,
    setCallDetails,
    mute,
    setMute,
    handleCaller,
  } = useContext(DialerContext);

  const dispatch = useDispatch();

  const resetSession = () => {
    setSession();
    setCallDetails();
    setCallStatus("idle");
  };

  const retrieveToken = () => {
    twilioAuthTrigger().then((response) => {
      if (response.data) {
        setDevice(new Device(response.data));
      }
    });
  };

  const handlePostCall = () => {
    if (callDetails?.leadId) {
      updateLead({
        id: callDetails.leadId,
        callCount: callDetails.callCount + 1 || 1,
      });
    }
    setCallStatus("post");
    setTimeout(resetSession, 5000);
  };

  const handleHangup = () => {
    if (callStatus === "pending") {
      session.reject();
    } else {
      session?.disconnect();
    }
  };

  const initializeSession = (newSession) => {
    setSession(newSession);
    newSession.on("disconnect", handlePostCall);
  };

  const handleDialOut = () => {
    if (device) {
      device
        .connect({ params: { To: `${callDetails.phone}?userId=${user.id}` } })
        .then((response) => {
          initializeSession(response);
          setCallStatus("call");
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar("Could not dial out lead", { variant: "error" }),
          );
        });
    } else {
      dispatch(
        enqueueSnackbar("Could not dial out lead", { variant: "error" }),
      );
    }
  };

  const handleIncomingCall = (newSession) => {
    handleCaller(newSession.from);
    setCallStatus("pending");
    initializeSession(newSession);
  };

  const handlePickup = () => {
    if (session && callStatus === "pending") {
      session.accept();
      setCallStatus("call");
    }
  };

  const handleMute = () => {
    if (session) {
      session.mute(!mute);
      setMute(!mute);
    }
  };

  useEffect(() => {
    if (device && device.state === "unregistered") {
      device.register();
      device.on("incoming", (call) => {
        handleIncomingCall(call);
      });
      device.on("tokenWillExpire", () => {
        twilioAuthTrigger().then((response) => {
          device.updateToken(response.data);
        });
      });
    }
  }, [device]);

  useEffect(() => {
    if (checkAuth("contacts", user, "full_access")) {
      retrieveToken();
      navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    }
  }, [user]);

  return {
    callStatus,
    setCallStatus,
    callDetails,
    setCallDetails,
    mute,
    setMute,
    handleCaller,
    handleDialOut,
    handleHangup,
    handlePickup,
    handleMute,
  };
};

export default useTwilioDialer;
