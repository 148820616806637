import React, { useCallback, createContext, useState } from "react";
import useTwilioDialer from "hooks/useTwilioDialer.hook";
import { useLazyGetContactsQuery } from "services/v1-slices/contacts-slice";

const Dialer = useTwilioDialer;

export const DialerContext = createContext();

export function DialerProvider({ ...props }) {
  const [device, setDevice] = useState(null);
  const [session, setSession] = useState(null);
  const [time, setTime] = useState(0);
  const [mute, setMute] = useState(false);
  const [callStatus, setCallStatus] = useState("idle");
  const [callDetails, setCallDetails] = useState();
  const [getContacts] = useLazyGetContactsQuery();

  const handleCaller = useCallback((phoneNumber) => {
    setCallStatus("pending");
    if (phoneNumber) {
      // The slice is getting rid of country code ex. +12508884336 -> 2508884336
      getContacts({
        phone: phoneNumber.slice(phoneNumber.length - 10, phoneNumber.length),
        view: "call",
        orderBy: "desc",
        sortBy: "updated_at",
        limit: 1,
      }).then(({ data: contacts }) => {
        const firstContact = contacts.data[0];
        if (firstContact) {
          setCallDetails({
            callType: "singleDialer",
            id: firstContact.id,
            type: "Contact",
            name: firstContact.name,
            phone: firstContact.phone,
          });
        } else {
          setCallDetails({
            callType: "singleDialer",
            id: null,
            type: "Contact",
            name: "Unknown Caller",
            phone: phoneNumber,
          });
        }
      });
    }
  });

  return (
    <DialerContext.Provider
      value={{
        Dialer,
        device,
        setDevice,
        session,
        setSession,
        time,
        setTime,
        callStatus,
        setCallStatus,
        mute,
        setMute,
        callDetails,
        setCallDetails,
        handleCaller,
      }}
      {...props}
    />
  );
}
